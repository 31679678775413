import React, { useEffect, useContext, useState, useRef } from "react";
import { UserContext, UserRoleContext } from "../lib/context";
import { roles } from "../lib/roles";
import { makeStyles } from "@material-ui/core/styles";
import firebase from "../lib/firebase";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import DeleteGroupDialog from "./deletegroupdialog";
import UnarchiveGroupDialog from "./unarchivegroupdialog";
import DeleteParticipantDialog from "./deleteparticipantdialog";
import AjouterParticipantDialog from "./ajouterparticipantdialog";
import GererEvaluateursDialog from "./gererevaluateursdialog";
import "../styles/groupcard.css";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 400,
    paddingBottom: 16,
    maxHeight: 600,
    minWidth: 210,
    margin: "40px 20px",
    overflow: "inherit",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "90vw",
      width: "90vw",
    },
    boxShadow:
      "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
    border: "none",
    borderRadius: "16px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",

    "& .MuiCardContent-root": {
      width: "100%",
    },
    flex: 1,
  },
  extendedCard: {
    minWidth: 310,
  },
  topTextContainer: {
    background: "white",
    // boxShadow:
    //   "rgba(0, 0, 0, 0.16) 0px 1px 4px",
    padding: 15,
    paddingTop: 10,
    color: "rgba(0,0,0, 0.7)",
    border: "1px solid rgba(0,0,0, 0.2)",
    borderRadius: "3px",
    // marginTop: "-40px",
    transform: "translateY(-60px)",
  },
  groupeTitle: {
    marginBottom: 10,
    fontSize: 22,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    flexDirection: "column",
  },

  creator: {
    fontSize: 14,
    fontStyle: "italic",
    textAlign: "right",
  },
  archivedText: {
    marginBottom: 14,
    textAlign: "center",
    width: "100%",
    color: "#0E7681",
  },
  date: {
    color: "grey",
    textAlign: "right",
  },
  cardAction: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    padding: "0 15px",
  },
  spaceBetween: {
    justifyContent: "space-between",
  },
  rectBtn: {
    width: 120,
    background: "#0E7681",
    transition: "all 0.15s ease-in-out",
    "&:hover": {
      background: "#0E7681",
      transform: "scale(1.03)",
    },
  },
  modifBtn: {
    width: 120,
    background: "#0e5181",
    transition: "all 0.15s ease-in-out",
    "&:hover": {
      background: "#0e5181",
      transform: "scale(1.03)",
    },
  },

  participant: {
    padding: 0,
    "& span": {
      fontSize: "14px",
    },
    display: "flex",
    width: "95%",
    color: "#6c6c6c",
  },
  participantName: {
    flex: "1 0 auto",
    width: "140px",
    "& span": {
      width: "180px",
    },
  },
  participantIconBtn: {
    flex: "1 1 auto",
    width: "50px",
    maxWidth: "40px",
    minWidth: "40px",
    alignSelf: "right",
  },
  participantContainer: {
    overflowX: "hidden",
    width: "100%",
    maxHeight: 200,
    overflowY: "auto",
    overFlowX: "hidden",
    marginTop: 10,
  },

  adminBtns: {
    margin: 0,
    padding: 0,
  },
}));

export default function GroupeCard({
  groupeData,
  evaluateGroupe,
  showBilan,
  index,
  setBottomNavValue,
  setGroupToModify,
}) {
  const classes = useStyles("");
  const db = firebase.firestore();
  const userRole = useContext(UserRoleContext);
  const user = useContext(UserContext);
  const cardRef = useRef();
  const [localGroupeData] = useState(groupeData);
  const [evalsData, setEvalsData] = useState(null);
  const [canBeModified, setCanBeModified] = useState(false);
  const animTimeout = useRef(null);
  useEffect(() => {
    let isMounted = true;
    const fetchEvalData = async () => {
      try {
        if (isMounted) {
          let fetchedEvalSnapshot = await db
            .collection("Evaluations")
            .where("groupe", "==", localGroupeData.id)
            .get();
          if (isMounted) {
            let tempEvalsData = [];
            fetchedEvalSnapshot.forEach(function (doc) {
              tempEvalsData.push({ ...doc.data(), id: doc.id });
            });
            if (tempEvalsData.length > 0) {
              setEvalsData(tempEvalsData);
            } else {
              setCanBeModified(true)
            }
          }
        }
      } catch (e) {
        if (isMounted) {
          console.log(e);
        }
      }
    };
    fetchEvalData();
    return () => {
      isMounted = false;
    };
  }, [db, localGroupeData]);

  useEffect(() => {
    if (cardRef && cardRef.current && cardRef.current.classList) {
      animTimeout.current = setTimeout(() => {
        if (cardRef && cardRef.current && cardRef.current.classList) {
          cardRef.current.classList.add("anim_in");
        }
      }, index * 165);
    }
    return () => {
      clearTimeout(animTimeout.current);
    };
  }, [cardRef, index]);
  const modifyThisGroupe = () => {
    setGroupToModify(localGroupeData);
    setBottomNavValue(2);
  };
  const isArchived = () => {
    return localGroupeData.data.archived;
  };
  return (
    <>
      {localGroupeData.data && localGroupeData.data && (
        <Card
          ref={cardRef}
          className={
            classes.root +
            " card_container" +
            " " +
            (userRole === roles.SUPER_ADMIN || userRole === roles.ADMIN
              ? classes.extendedCard
              : "")
          }
        >
          {/* {userRole <= roles.ADMIN && (
            <ChangeGroupImageDialog
              groupeData={localGroupeData}
              setlocalGroupeData={setlocalGroupeData}
            />
          )} */}

          {/* <CardMedia
            component="img"
            height="140"
            className={classes.media}
            image={
              localGroupeData.data.imageURL
                ? localGroupeData.data.imageURL
                : process.env.PUBLIC_URL + "/img/eval.jpg"
            }
            title="Groupe"
          /> */}
          <CardContent>
            {isArchived() && (
              <Typography
                className={classes.archivedText}
                variant="body2"
                color="textSecondary"
                component="p"
              >
                Archivé
              </Typography>
            )}

            <div className={classes.topTextContainer + " top_text_container"}>
              <div className={classes.groupeTitle}>
                <span className={classes.titre}>
                  {" "}
                  {localGroupeData.data.titre}
                </span>
                <span className={classes.creator}>
                  {"créé par " + localGroupeData.data.creator}
                </span>
              </div>
              <Typography
                className={classes.date}
                variant="body2"
                color="textSecondary"
                component="p"
              >
                {new Date(
                  localGroupeData.data.date.seconds * 1000
                ).toLocaleDateString("Fr-fr", {
                  weekday: "long",
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })}
              </Typography>
            </div>

            <div className={classes.participantContainer}>
              {localGroupeData.data.participants.map((participant) => {
                return (
                  <div key={participant}>
                    <ListItem className={classes.participant}>
                      <ListItemText
                        className={classes.participantName}
                        primary={participant}
                      />
                      {userRole <= roles.ADMIN && !isArchived() && (
                        <ListItemIcon className={classes.participantIconBtn}>
                          <DeleteParticipantDialog
                            participant={participant}
                            groupeData={localGroupeData}
                          />
                        </ListItemIcon>
                      )}
                      {/* <ListItemIcon className={classes.participantIconBtn}>
                    <IconButton size="medium">
                      <VisibilityIcon />
                    </IconButton>
                  </ListItemIcon> */}

                      {/* <ListItemIcon className={classes.participantIconBtn}>
                    <IconButton size="medium">
                      <EditIcon />
                    </IconButton>
                  </ListItemIcon> */}
                    </ListItem>
                    <Divider />
                  </div>
                );
              })}
            </div>
          </CardContent>

          <CardActions
            className={
              classes.cardAction +
              " " +
              (userRole === roles.SUPER_ADMIN ||
              (groupeData.data.evaluateurs.includes(user.uid) &&
                userRole <= roles.EVALUATEUR)
                ? classes.spaceBetween
                : "")
            }
          >
            {evalsData && (
              <Button
                className={classes.rectBtn}
                onClick={() => {
                  showBilan(localGroupeData);
                }}
                variant="contained"
                size="small"
                color="primary"
              >
                BILAN
              </Button>
            )}
            {!evalsData && canBeModified && !isArchived() && (
              <Button
                className={classes.rectBtn + " " + classes.modifBtn}
                onClick={() => {
                  modifyThisGroupe();
                }}
                variant="contained"
                size="small"
                color="primary"
              >
                MODIFIER
              </Button>
            )}

            {userRole <= roles.EVALUATEUR &&
              groupeData.data.evaluateurs.includes(user.uid) &&
              !isArchived() && (
                <Button
                  className={classes.rectBtn}
                  variant="contained"
                  size="small"
                  color="primary"
                  onClick={() => {
                    evaluateGroupe(localGroupeData);
                  }}
                >
                  évaluer
                </Button>
              )}
            {userRole <= roles.ADMIN && (
              <>
                <DeleteGroupDialog groupeData={localGroupeData} />
                {isArchived() && (
                  <UnarchiveGroupDialog groupeData={localGroupeData} />
                )}
                {!isArchived() && (
                  <AjouterParticipantDialog groupeData={localGroupeData} />
                )}
                {!isArchived() && (
                  <GererEvaluateursDialog groupeData={localGroupeData} />
                )}
              </>
            )}
          </CardActions>
        </Card>
      )}
    </>
  );
}
