import React from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

export default function NameAndBarVisual({
  name,
  percentage,
  isLast,
  isClassement,
  index,
}) {
  return (
    <div
      className={
        "horizontal_bar_card_name_and_bar" +
        " " +
        (isClassement ? "classement" : "")
      }
    >
      <div className="horizontal_bar_card_p_name">
        {isClassement ? (
          <>
            <span style={{fontWeight:"bold"}}>{index+1} - </span>
            <span>{name}</span>
          </>
        ) : (
          name
        )}
      </div>
      <div
        className={
          "horizontal_bar_card_percentage" +
          " " +
          (isLast ? "is_last" : "") +
          " " +
          (percentage <= 33
            ? "red_bar"
            : percentage <= 66
            ? "orange_bar"
            : "green_bar")
        }
      >
        <LinearProgressWithLabel value={percentage} />
      </div>
    </div>
  );
}
