import React, { useEffect, useRef } from "react";
import Chart from "./chart";
import Chip from "@material-ui/core/Chip";
import CommentsWindow from "./commentswindow";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import PhotoLibraryIcon from "@material-ui/icons/PhotoLibrary";
import IconButton from "@material-ui/core/IconButton";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import "../styles/evalcard.css";

export default function EvalCard({
  index,
  chartType,
  results,
  percentage,
  pName,
  groupeId,
  groupeTitre,
  evaluateurName,
  viewFaiblesses
}) {
  const evalCardContainerElem = useRef(null);
  const animTimeout = useRef(null);
  const clonedResults = { ...results };
  delete clonedResults.name;
  useEffect(() => {
    let isMounted = true;
    if (evalCardContainerElem && evalCardContainerElem.current) {
      animTimeout.current = setTimeout(
        () => {
          if (isMounted) {
            evalCardContainerElem.current.classList.add("anim_in");
          }
        },
        index * 100 < 400 ? index * 100 : 400
      );
    }
    return () => {
      isMounted = false;
      clearTimeout(animTimeout.current);
    };
  }, [evalCardContainerElem, index]);

  let pointFort = [];
  let pointFaible = [];
  let pointFortLimit = 65;
  let pointFaibleLimit = 50;
  Object.entries(clonedResults).forEach(([skill, score]) => {
    let scoreFormated =
      ((score.evalNumber ) / score.iterations) * 25;
    if (scoreFormated > pointFortLimit) {
      pointFort.push(skill);
    } else if (scoreFormated < pointFaibleLimit) {
      pointFaible.push(skill);
    }
  });
  const exportCardBilan = (outputType) => {
    let bilanTabs = document.querySelector(".bilan_tabs");
    let chartToolBars = document.querySelectorAll(".apexcharts-toolbar");
    let evalCardContainers = document.querySelectorAll(".eval_card_container");
    let commentsLabel = document.querySelectorAll(".comments_label");
    let unwantedLines = document.querySelectorAll(".apexcharts-ycrosshairs");
    let commentsContainerElems = document.querySelectorAll(
      ".comments_for_export"
    );
    let exportFunc = (withComment) => {
      if (commentsLabel) {
        commentsLabel.forEach((label) => {
          label.style.display = "none";
        });
      }
      if (withComment && commentsContainerElems) {
        commentsContainerElems.forEach((commentsContainer) => {
          commentsContainer.style.display = "flex";
        });
      }
      if (unwantedLines) {
        unwantedLines.forEach((line) => {
          line.style.display = "none";
        });
      }
      if (bilanTabs) {
        bilanTabs.style.display = "none";
      }
      if (chartToolBars) {
        chartToolBars.forEach((chartToolBar) => {
          chartToolBar.style.display = "none";
        });
      }
      if (evalCardContainers) {
        evalCardContainers.forEach((evalCardContainer) => {
          evalCardContainer.style.border = "1px solid rgba(0,0,0,0.2)";
        });
      }
      if (evalCardContainerElem.current) {
        if (outputType === "pdf") {
          var worker = window
            .html2pdf()
            // .set({
            //   jsPDF: {
            //     unit: "px",
            //     format: [
            //       evalCardContainerElem.current.offsetHeight*1.2,
            //       evalCardContainerElem.current.offsetWidth*1.2,

            //     ],
            //     hotfixes: ["px_scaling"],
            //   },
            // })
            .from(evalCardContainerElem.current)
            .save(
              groupeTitre +
                "_" +
                pName +
                "_carte_individuelle_" +
                (evaluateurName ? "evalué_par_" + evaluateurName : "_total") +
                ".pdf"
            );
        } else {
          var worker = window
            .html2pdf()
            .set({
              jsPDF: {
                unit: "mm",
                format: [100, 200],
                orientation: "portrait",
              },
            })
            .from(evalCardContainerElem.current)
            .outputImg("datauri")
            .then((test) => {
              let link = document.createElement("a");
              link.href = test;
              link.target = "_blank";
              link.download =
                groupeTitre +
                "_" +
                pName +
                "_carte_individuelle_" +
                (evaluateurName ? "evalué_par_" + evaluateurName : "_total") +
                ".jpeg";
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            });
        }
      }
      if (commentsLabel) {
        setTimeout(() => {
          commentsLabel.forEach((label) => {
            label.style.display = "";
          });
        }, 100);
      }
      if (bilanTabs) {
        setTimeout(() => {
          bilanTabs.style.display = "";
        }, 100);
      }
      if (chartToolBars) {
        setTimeout(() => {
          chartToolBars.forEach((chartToolBar) => {
            chartToolBar.style.display = "flex";
          });
        }, 100);
      }
      if (evalCardContainers) {
        setTimeout(() => {
          evalCardContainers.forEach((evalCardContainer) => {
            evalCardContainer.style.border = "none";
          });
        }, 100);
      }
      if (withComment && commentsContainerElems) {
        setTimeout(() => {
          commentsContainerElems.forEach((commentsContainer) => {
            commentsContainer.style.display = "none";
          });
        }, 100);
      }
    };
    if (commentsContainerElems.length > 0) {
      confirmAlert({
        title: "Exportation",
        message: "Exporter avec les commentaires ?",
        buttons: [
          {
            label: "Oui",
            onClick: () => exportFunc(true),
          },
          {
            label: "Non",
            onClick: () => exportFunc(false),
          },
        ],
      });
    } else {
      exportFunc(false)
    }

  };
  return (
    <div ref={evalCardContainerElem} className="eval_card_container">
      <IconButton
        className="to_pdf_btn export_btn"
        onClick={() => {
          exportCardBilan("pdf");
        }}
      >
        <PictureAsPdfIcon style={{ color: "#0e7681" }} />
      </IconButton>
      <IconButton
        className="to_jpeg_btn  export_btn"
        onClick={() => {
          exportCardBilan("jpeg");
        }}
      >
        <PhotoLibraryIcon style={{ color: "#0e7681" }} />
      </IconButton>

      <div
        className={
          "radar_chart " +
          (percentage < 33.33
            ? "red_back"
            : percentage < 66.66
            ? "orange_back"
            : "green_back") +
          " type_" +
          chartType
        }
      >
        {Object.values(clonedResults).length > 0 && (
          <Chart results={clonedResults} pName={pName} type={chartType} />
        )}
      </div>
      {/* <div className="total">
        <div className="total_label">Total</div>
        <div
          className={
            "total_num " +
            (percentage < 33.33
              ? "total_red"
              : percentage < 66.66
              ? "total_orange"
              : "total_green")
          }
        >
          {percentage}%
        </div>
      </div> */}
      {pointFort && pointFort.length !== 0 && (
        <div className="point_faible">
          <div className="point_fort_faible_label">
            {pointFort.length > 0 ? "Points forts:" : "Point fort"}
          </div>
          <div className="chip_fort_faible_container">
            {pointFort.map((skill) => (
              <Chip
                key={skill + Math.random()}
                variant="outlined"
                label={skill}
                size="small"
                style={{
                  borderColor: "rgb(14, 118, 129)",
                  color: "rgb(14, 118, 129)",
                }}
                className="chip_fort_faible"
              />
            ))}
          </div>
        </div>
      )}
      {viewFaiblesses && pointFaible && pointFaible.length !== 0 && (
        <div className="point_faible">
          <div className="point_fort_faible_label">
            {pointFaible.length > 0
              ? "Axes d'amélioration:"
              : "Axe d'amélioration:"}
          </div>
          <div className="chip_fort_faible_container">
            {pointFaible.map((skill) => (
              <Chip
                key={skill + Math.random()}
                variant="outlined"
                label={skill}
                size="small"
                style={{
                  borderColor: "rgb(14, 118, 129)",
                  color: "rgb(14, 118, 129)",
                }}
                className="chip_fort_faible"
              />
            ))}
          </div>
        </div>
      )}

      <CommentsWindow
        groupeId={groupeId}
        evaluatedUserName={pName}
        inEvalCard={true}
      />
    </div>
  );
}
