import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { UserContext, UserRoleContext } from "../lib/context";
import { roles } from "../lib/roles";
import firebase from "../lib/firebase";

const useStyles = makeStyles((theme) => ({
  commentContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    flexDirection: "column",
    background: "#eff2f5",
    padding: 15,
    borderRadius: 10,
    margin: "10px 0",
    width: "100%",
  },
  author: {
    fontWeight: 800,
    fontSize: "0.9rem",
  },
  message: {
    fontSize: "1rem",
    color: "#626262",
    fontWeight: 100,
  },
  dateSent: {
    fontWeight: 100,
    fontStyle: "italic",
    fontSize: "0.8rem",
  },
}));

export default function ControlledOpenSelect({
  comment,
  setComments,
  setCommentsNb,
}) {
  const classes = useStyles();
  const userRole = useContext(UserRoleContext);
  const user = useContext(UserContext);
  const db = firebase.firestore();
  const deleteComment = async () => {
    try {
      await db.collection("Comments").doc(comment.id).delete();
      console.log("Comment deleted");
      setComments((prv) => prv.filter((c) => c.id !== comment.id));
      setCommentsNb((prv) => prv - 1);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className={classes.commentContainer}>
      <div className={classes.author}>
        {comment.author}{" "}
        <span className={classes.dateSent}>
          {new Date(comment.dateSent).toLocaleDateString("fr-FR", {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
          })}
        </span>
        {(user.uid === comment.authorUid || userRole <= roles.ADMIN) && (
          <IconButton onClick={deleteComment} size="medium" color="default">
            <DeleteForeverIcon />
          </IconButton>
        )}
      </div>
      <div className={classes.message}>{comment.message}</div>
    </div>
  );
}
