import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
const useStyles = makeStyles((theme) => ({
  buttons: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 10,
  },
}));

export default function DecoDialog({ logout, opened, setDecoDialogOpen }) {
  const classes = useStyles();
  const handleClose = () => {
    setDecoDialogOpen(false);
  };
  const handleConfirm = () => {
    setDecoDialogOpen(false);
    logout();
  };

  return (
    <div>
      <Dialog
        open={opened}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Se déconnecter ?</DialogTitle>

        <DialogActions disableSpacing={true} className={classes.buttons}>
          <Button onClick={handleClose} color="primary">
            Non
          </Button>
          <Button onClick={handleConfirm} color="primary" autoFocus>
            Oui
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
