import React, { useState, useContext } from "react";
import { UserContext } from "../lib/context";
import Typography from "@material-ui/core/Typography";
import ChangeNameDialog from "./changenamedialog";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  title: {
    // flex: 1,
    textAlign: "center",
    padding: 0,
    margin: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    lineHeight: "20px",
    marginRight: 0
  },
}));

export default function FormDialog({ darkColor ,hideEditBtn}) {
  const [editedUserName, setEditedUserName] = useState(null);
  const user = useContext(UserContext);
  const classes = useStyles();

  return (
    <Typography variant="subtitle1" className={classes.title}>
      <div style={{ color: darkColor ? "#0e7681" : "white" }}>
        {(editedUserName
          ? editedUserName
          : user.displayName
          ? user.displayName
          : user.email.split("@")[0]
        ).toUpperCase()}
      </div>
      {!hideEditBtn && <ChangeNameDialog setEditedUserName={setEditedUserName} darkColor={darkColor} />}
    </Typography>
  );
}
