import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";

const useStyles = makeStyles((theme) => ({
  root: {
        display: "flex",
      width:"184px",
    flexWrap: "wrap",
    margin: "auto",
    "& .MuiOutlinedInput-input": {
      // padding:  0,
    },
    "& .MuiInputBase-input": {
      color: "white",
    },
    "& .MuiFormLabel-root": {
      color: "white",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "white",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#0e7680",
    },
  },
  rootSmallHeight: {
    display: "flex",
    flexWrap: "wrap",
    margin: "auto",
    "& .MuiOutlinedInput-input": {
      paddingTop: "5px",
      paddingBottom: "5px",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-marginDense": {
      transform: "translate(14px, 6px) scale(1)",
      fontSize: 14,
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink ": {
      transform: "translate(14px, -6px) scale(0.75)",
    },
    "& .MuiInputBase-input": {
      fontSize: 14,
    },
  },

  textField: {
    "& *:": {
      color: "white",
    },
    // width: "25ch",
  },
}));

export default function FilterParticipantsInput({
  smallWindowHeight,
  setFilterName,
}) {
  const classes = useStyles({ smallWindowHeight });

  const handleChange = (event) => {
    setFilterName(event.target.value);
  };

  return (
    <div className={smallWindowHeight ? classes.rootSmallHeight : classes.root}>
      <FormControl
        className={clsx(classes.margin, classes.textField)}
        variant="outlined"
      >
        <TextField
          label="Filtrer les participants"
          size="small"
          variant="outlined"
          onChange={handleChange}
        />
      </FormControl>
    </div>
  );
}
