import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import DashboardIcon from "@material-ui/icons/Dashboard";
import ExtensionIcon from "@material-ui/icons/Extension";
import FormatListNumberedIcon from "@material-ui/icons/FormatListNumbered";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 38,
  },
  link: {
    display: "flex",
    cursor: "pointer",
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
  label: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  selected: {
    color: "#107581!important",
  },
}));

export default function IconBreadcrumbs({ typeDeBilan, setTypeDeBilan }) {
  const classes = useStyles();
  const handleClick = (bilanType) => {
    setTypeDeBilan(bilanType);
  };
  return (
    <Breadcrumbs aria-label="breadcrumb" className={classes.root + " " + "secondary_bilan_tabs"}>
      <Typography
        color="inherit"
        id="cartes_individuelles"
        onClick={() => handleClick("cartes_individuelles")}
        className={
          classes.link +
          " " +
          (typeDeBilan === "cartes_individuelles" ? classes.selected : "")
        }
      >
        <DashboardIcon className={classes.icon} />
        <span className={classes.label}>Cartes individuelles</span>
      </Typography>
      <Typography
        color="inherit"
        id="cartes_par_soft_skills"
        onClick={() => handleClick("cartes_par_soft_skills")}
        className={
          classes.link +
          " " +
          (typeDeBilan === "cartes_par_soft_skills" ? classes.selected : "")
        }
      >
        <ExtensionIcon className={classes.icon} />
        <span className={classes.label}>Soft-skills</span>
      </Typography>
      <Typography
        color="inherit"
        id="classement"
        onClick={() => handleClick("classement")}
        className={
          classes.link +
          " " +
          (typeDeBilan === "classement" ? classes.selected : "")
        }
      >
        <FormatListNumberedIcon className={classes.icon} />
        <span className={classes.label}>Classement</span>
      </Typography>
    </Breadcrumbs>
  );
}
