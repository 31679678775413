import React, { useContext } from "react";
import { SetGroupesContext } from "../lib/context";
import firebase from "../lib/firebase";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import DeleteSweepIcon from "@material-ui/icons/DeleteSweep";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";

const useStyles = makeStyles((theme) => ({
  titreGroupe: {
    fontSize: 18,
    fontWeight: "bold",
    color: "black",
  },
  deleteBtn: {
    color: "#707070",
    margin: 0,
    padding: 8,
  },
  firstButton: { marginRight: "auto" },
}));

export default function DeleteGroupDialog({ groupeData }) {
  const classes = useStyles("");
  const [open, setOpen] = React.useState(false);
  const db = firebase.firestore();
  const setGroupes = useContext(SetGroupesContext);

  const deleteGroupe = () => {
    db.collection("Groupes")
      .doc(groupeData.id)
      .delete()
      .then(function () {
        console.log("Document successfully deleted!");
        setGroupes((prv) => [...prv.filter((grp) => grp.id !== groupeData.id)]);
      })
      .catch(function (error) {
        console.error("Error removing document: ", error);
        handleClose();
      });
  };
  const archiveGroupe = () => {
    db.collection("Groupes")
      .doc(groupeData.id)
      .update({ archived: true })
      .then(function () {
        console.log("Document successfully archived!");
        handleClose();
        setGroupes((prv) => [
          ...prv.map((grp) => {
            if (grp.id === groupeData.id) {
              grp.data.archived = true;
              return { ...grp };
            }
            return grp;
          }),
        ]);
      })
      .catch(function (error) {
        console.error("Error archiving document: ", error);
        handleClose();
      });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Tooltip
        arrow
        TransitionComponent={Zoom}
        title={
          groupeData.data.archived
            ? "Supprimer la session"
            : "Archiver ou supprimer la session"
        }
      >
        <IconButton
          className={classes.deleteBtn + " delete_grp_btn"}
          onClick={handleClickOpen}
          size="medium"
          color="primary"
        >
          {groupeData.data.archived ? (
            <DeleteForeverIcon />
          ) : (
            <DeleteSweepIcon />
          )}
        </IconButton>
      </Tooltip>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {groupeData.data.archived
            ? "Supprimer la session"
            : "Supprimer ou archiver la session"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {groupeData.data.archived
              ? "Voulez vous supprimer définitivement la session: "
              : "Voulez vous supprimer définitivement ou archiver la session: "}
            <span className={classes.titreGroupe}>{groupeData.data.titre}</span>{" "}
            ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.firstButton}
            onClick={handleClose}
            color="primary"
          >
            ANNULER
          </Button>
          {!groupeData.data.archived && (
            <Button
              onClick={archiveGroupe}
              color="primary"
              variant="contained"
              autoFocus
            >
              ARCHIVER
            </Button>
          )}

          <Button
            onClick={deleteGroupe}
            color="secondary"
            variant="contained"
            autoFocus
          >
            SUPPRIMER
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
