import React, { useState } from "react";
import firebase from "../lib/firebase";
import CircularProgress from "@material-ui/core/CircularProgress";
import EmailIcon from "@material-ui/icons/Email";
import LockIcon from "@material-ui/icons/Lock";
import Alert from "@material-ui/lab/Alert";
import ForgotPasswordDialog from "./forgotpassworddialog";
import "../styles/loginform.css";

export default function AuthForms() {
  const [loginValues, setLoginValues] = useState({
    mail: "",
    password: "",
    retypedPassword: "",
  });
  const [createAccount, setCreateAccount] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loadingLogin, setLoadingLogin] = useState(false);
  const [forgotPasswordOpen, setForgotPasswordOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState({
    msg: "",
    type: "success",
    showed: false,
    timeoutNumber: null,
  });

  const auth = firebase.auth();
  auth.useDeviceLanguage();
  const handleChangeLoginValues = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setLoginValues((prevState) => ({ ...prevState, [name]: value }));
  };
  const handleSubmitLoginValues = async (e) => {
    e.preventDefault();
    try {
      let validationError = null;

      if (loginValues.mail.length === 0) {
        validationError = "Veuillez renseigner un email";
      } else if (loginValues.password.length < 6) {
        validationError =
          "Le mot de passe doit comporter au moins 6 caractères";
      } else if (
        createAccount &&
        loginValues.password !== loginValues.retypedPassword
      ) {
        validationError = "Les mots de passe ne correspondent pas";
      }
      if (!validationError) {
        setLoadingLogin(true);
        if (createAccount) {
          await auth.createUserWithEmailAndPassword(
            loginValues.mail.toLowerCase().trim(),
            loginValues.password
          );
        } else {
          await auth.signInWithEmailAndPassword(
            loginValues.mail.toLowerCase().trim(),
            loginValues.password
          );
        }
      } else {
        setErrorMessage(validationError);
        setLoadingLogin(false);
      }
    } catch (err) {
      console.log(err);
      let frenchErroMsg = null;
      if (err.code === "auth/user-not-found") {
        frenchErroMsg = "Cet utilisateur n'existe pas";
      } else if (err.code === "auth/wrong-password") {
        frenchErroMsg = "Mauvais mot de passe";
      } else if (err.code === "auth/email-already-in-use") {
        frenchErroMsg =
          "L'adresse e-mail est déjà utilisée par un autre compte";
      }
      setErrorMessage(frenchErroMsg ? frenchErroMsg : err.message);
      setLoadingLogin(false);
    }
  };
  const showAlertMessage = (msg, type) => {
    if (msg.length > 0) {
      clearTimeout(alertMessage.timeoutNumber);
      let timeoutNumber = setTimeout(() => {
        setAlertMessage({ msg: "", showed: false });
      }, 3000);
      setAlertMessage({ msg, type, showed: true, timeoutNumber });
    }
  };

  return (
    <div className="auth-container">
      <img
        width="100px"
        className="logo"
        alt="synergie family logo"
        src={process.env.PUBLIC_URL + "/img/logo_synergie_family.svg"}
      />
      <div className="auth-window">
        <div className="auth-header">
          <span
            onClick={() => {
              setCreateAccount(false);
            }}
            className={`top-btn login-header-btn ${
              !createAccount ? "active" : ""
            }`}
          >
            CONNEXION
          </span>
          <span
            onClick={() => {
              setCreateAccount(true);
            }}
            className={`top-btn login-header-btn ${
              createAccount ? "active" : ""
            }`}
          >
            CRÉER UN COMPTE
          </span>
        </div>

        <form onSubmit={handleSubmitLoginValues}>
          <div className="form-input">
            <input
              className="auth-input"
              type="email"
              name="mail"
              value={loginValues.mail}
              placeholder="Mail"
              autoComplete="off"
              onChange={handleChangeLoginValues}
            />
            <EmailIcon className="input-icon mail-icon" />

            <input
              className="auth-input "
              type="password"
              name="password"
              value={loginValues.password}
              placeholder="Mot de passe"
              autoComplete="off"
              onChange={handleChangeLoginValues}
            />
            <LockIcon className="input-icon password-icon" />
            {createAccount && (
              <input
                className="auth-input "
                type="password"
                name="retypedPassword"
                value={loginValues.retypedPassword}
                placeholder="Retaper le mot de passe"
                autoComplete="off"
                onChange={handleChangeLoginValues}
              />
            )}

            {createAccount && (
              <LockIcon className="input-icon retype-password-icon" />
            )}
          </div>
          {loadingLogin ? (
            <div className="loading-login">
              <CircularProgress />
            </div>
          ) : (
            <input
              className="submit-btn login-btn"
              type="submit"
              value={createAccount ? "CRÉER UN COMPTE" : "SE CONNECTER"}
            />
          )}

          <h1
            style={{
              color: "#FF3232",
              fontSize: "14px",
              textAlign: "center",
              marginTop: "15px",
              marginBottom: "0px",
              fontWeight: 100,
            }}
          >
            {errorMessage}
          </h1>
          <Alert
            className={"alert-msg " + (alertMessage.showed ? "showed" : "")}
            severity={alertMessage.type}
          >
            {alertMessage.msg}
          </Alert>
          {!createAccount && !loadingLogin && (
            <div
              className="forgot-password"
              onClick={() => {
                setForgotPasswordOpen(true);
                console.log(forgotPasswordOpen);
              }}
            >
              Mot de passe oublié ?
            </div>
          )}
        </form>
      </div>
      {forgotPasswordOpen && (
        <ForgotPasswordDialog
          setForgotPasswordOpen={setForgotPasswordOpen}
          showAlertMessage={showAlertMessage}
        />
      )}
    </div>
  );
}
