import React, { useEffect, useState, useContext } from "react";
import Joyride from "react-joyride";
import {
  SetGroupesContext,
  UserRoleContext,
  UserContext,
} from "../lib/context";
import firebase from "../lib/firebase";
import GroupeCard from "./groupcard";
import { makeStyles , withStyles} from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import anime from "animejs";
import { roles } from "../lib/roles";
import Alert from "@material-ui/lab/Alert";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

const GreenSwitch = withStyles({
  switchBase: {
    '&$checked': {
      color: "#0E7681",
    },
    '&$checked + $track': {
      backgroundColor: "#0E7681",
    },
  },
  checked: {},
  track: {},
})(Switch);
const useStyles = makeStyles((theme) => ({
  cards: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      alignItems: "center",
    },
    width: "100%",
    height: "100%",
    "& .MuiCircularProgress-root": {
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      margin: "auto",
    },
  },
  skeletons: {
    marginTop: 20,
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      alignItems: "center",
    },
    width: "100%",
    "& .MuiSkeleton-rect": {
      marginBottom: 15,
    },
    overflow: "hidden",
    maxHeight: "calc(100vh - 250px)",
  },
  skeleton: {
    margin: 20,
    width: 500,
    height: 500,
    marginBottom: 20,
  },
  groupeView: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    minHeight: "calc(100vh - 200px)",
    paddingTop: 30,
  },
  viewArchivedSwitch: { position: "absolute", top: 15 },
  createGrpBtn: {
    width: 300,
    height: 80,
    fontSize: 22,
    borderRadius: "20px",
    background: "#ebebeb",
    boxShadow: "20px 20px 60px #c8c8c8, -20px -20px 60px #ffffff",
    // background: "linear-gradient( 136deg,#f14f83 0%,#be1c50 100%)",
    // color: "white",
    // "&:hover": {

    //   backgroundColor: "#1F4695"
    // },
    "&:hover": {
      color: "white",
      background: "linear-gradient( 136deg,#f14f83 0%,#be1c50 100%)",
    },
  },
  ask_admin_container: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  illustrationImg: {
    maxWidth: "85vw",
  },
  hint: {
    textAlign: "center",
    width: "100%",
    maxWidth: 600,
    padding: "10px 20px",
  },
}));
export default function GroupeView({
  evaluateGroupe,
  showBilan,
  handleBottomNavChange,
  refreshRdm,
  setBottomNavValue,
  setGroupToModify,
}) {
  const classes = useStyles("");
  const db = firebase.firestore();
  const userRole = useContext(UserRoleContext);
  const user = useContext(UserContext);
  const [groupes, setGroupes] = useState([]);
  const [loadingGroupes, setLoadingGroupes] = useState(false);
  const createGrpBtnRef = React.createRef();
  const [onboardingSteps, setOnboardingSteps] = useState([]);
  const [viewArchived, setViewArchived] = useState(false);

  const refreshGroupes = () => {
    setLoadingGroupes(true);
    db.collection("Groupes")
      .orderBy("date", "desc")
      .get()
      .then(function (querySnapshot) {
        let groupes = [];
        querySnapshot.forEach(function (doc) {
          let canAccessThisGroupe = false;
          if (userRole <= roles.ADMIN) {
            canAccessThisGroupe = true;
          } else if (
            doc.data() &&
            doc.data().evaluateurs &&
            doc.data().evaluateurs.includes(user.uid)
          ) {
            canAccessThisGroupe = true;
          }
          if (canAccessThisGroupe) {
            groupes = [...groupes, { id: doc.id, data: doc.data() }];
          }
        });
        setGroupes(groupes);
        setLoadingGroupes(false);
      });
  };
  useEffect(() => {
    let isMounted = true;
    setLoadingGroupes(true);
    db.collection("Groupes")
      .orderBy("date", "desc")
      .get()
      .then(function (querySnapshot) {
        if (isMounted) {
          let groupes = [];
          querySnapshot.forEach(function (doc) {
            let canAccessThisGroupe = false;
            if (userRole <= roles.ADMIN) {
              canAccessThisGroupe = true;
            } else if (
              doc.data() &&
              doc.data().evaluateurs &&
              doc.data().evaluateurs.includes(user.uid)
            ) {
              canAccessThisGroupe = true;
            }
            if (canAccessThisGroupe) {
              groupes = [...groupes, { id: doc.id, data: doc.data() }];
            }
          });
          let showGroupOnboarding = false;
          let showAdminOnboarding = false;
          if (!localStorage.getItem("admin_group_onboarding_done")) {
            if (groupes.length > 0) {
              showGroupOnboarding = true;
              localStorage.setItem("admin_group_onboarding_done", "yes");
            }
          }
          if (!localStorage.getItem("admin_onboarding_done")) {
            if (userRole <= roles.ADMIN) {
              showAdminOnboarding = true;
              localStorage.setItem("admin_onboarding_done", "yes");
            }
          }

          if (showGroupOnboarding || showAdminOnboarding) {
            let tempOnboardingSteps = [];
            if (showAdminOnboarding) {
              tempOnboardingSteps = [
                ...tempOnboardingSteps,
                {
                  target: ".manage_users_btn",
                  content:
                    "Attribuez les rôles d'admin, évaluateur ou invité aux différents utilisateurs.",
                  disableBeacon: true,
                },
                {
                  target: ".create_grp_btn",
                  content: "Créez une nouvelle session.",
                  disableBeacon: true,
                },
                {
                  target: ".grp_btn",
                  content: "Voir toutes les sessions.",
                  disableBeacon: true,
                },
              ];
            }
            if (showGroupOnboarding) {
              tempOnboardingSteps = [
                ...tempOnboardingSteps,
                {
                  target: ".grp_cards",
                  content:
                    "Chaque carte represente une session avec ses participants à évaluer.",
                  disableBeacon: true,
                },
                {
                  target: ".delete_grp_btn",
                  content: "Supprimer cette session.",
                  disableBeacon: true,
                },
                {
                  target: ".add_participant_btn",
                  content: "Ajouter un participant à évaluer.",
                  disableBeacon: true,
                },
                {
                  target: ".manage_evaluators_btn",
                  content:
                    "Choisir les utilisateurs qui peuvent voir cette session.",
                  disableBeacon: true,
                },
              ];
            }
            setOnboardingSteps(tempOnboardingSteps);
          }
          setGroupes(groupes);
          setLoadingGroupes(false);
        }
      });
    return () => {
      isMounted = false;
    };
  }, [refreshRdm, db, userRole, user.uid]);
  useEffect(() => {
    anime({
      targets: createGrpBtnRef.current,
      translateY: [-50, 0],
      opacity: [0, 1],
      easing: "easeOutQuad",
      duration: 1500,
    });
  }, [loadingGroupes, createGrpBtnRef]);
  const handleSwitchViewArchived = (e) => {
    setViewArchived(e.target.checked);
  };
  return (
    <SetGroupesContext.Provider value={setGroupes}>
      <Joyride
        steps={onboardingSteps}
        locale={{
          back: "Précedent",
          close: "Fermer",
          last: "Terminé",
          next: "Suivant",
          skip: "Ignorer",
        }}
        continuous={true}
        showSkipButton={true}
        run={onboardingSteps.length > 0}
        styles={{
          options: {
            zIndex: 10000,
            primaryColor: "#0E7681",
          },
        }}
      />

      <div className={classes.groupeView}>
        <FormControlLabel
          className={classes.viewArchivedSwitch}
          control={
            <GreenSwitch
              onChange={handleSwitchViewArchived}
              // color="primary"
            />
          }
          label="Voir sessions archivées"
        />

        <div className={classes.cards + " grp_cards"}>
          {userRole === 4 ? (
            <Alert severity="info">Vous avez été banni par un Admin</Alert>
          ) : loadingGroupes ? (
            <CircularProgress disableShrink />
          ) : groupes.length === 0 && userRole <= roles.ADMIN ? (
            <Button
              ref={createGrpBtnRef}
              className={classes.createGrpBtn}
              variant="contained"
              onClick={() => {
                handleBottomNavChange(null, 2);
              }}
            >
              Créer une session
            </Button>
          ) : groupes.length === 0 ? (
            <div className={classes.ask_admin_container}>
              <img
                className={classes.illustrationImg}
                alt="ask admin illustration"
                src={process.env.PUBLIC_URL + "/img/ask_admin.png"}
              />
              <div className={classes.hint}>
                Un email a été envoyé pour alerter un Admin. Vous devez attendre
                son autorisation pour voir les sessions et pouvoir les évaluer
              </div>
            </div>
          ) : (
            groupes
              .filter(
                (gr) => !gr.data.archived || (gr.data.archived && viewArchived)
              )
              .map((groupeData, index) => {
                return (
                  <GroupeCard
                    key={groupeData.id}
                    groupeData={groupeData}
                    refreshGroupes={refreshGroupes}
                    evaluateGroupe={evaluateGroupe}
                    showBilan={showBilan}
                    index={index}
                    setBottomNavValue={setBottomNavValue}
                    setGroupToModify={setGroupToModify}
                  />
                );
              })
          )}
        </div>
      </div>
    </SetGroupesContext.Provider>
  );
}
