import React, { useEffect, useRef } from "react";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import PhotoLibraryIcon from "@material-ui/icons/PhotoLibrary";
import IconButton from "@material-ui/core/IconButton";
import NameAndBarVisual from "./nameandbarvisual";
import "../styles/evalcard.css";

export default function SkillCard({
  index,
  results,
  skillName,
  groupeTitre,
  evaluateurName,
}) {
  const evalCardContainerElem = useRef(null);
  const animTimeout = useRef(null);
  useEffect(() => {
    let isMounted = true;
    if (evalCardContainerElem && evalCardContainerElem.current) {
      animTimeout.current = setTimeout(
        () => {
          if (isMounted) {
            evalCardContainerElem.current.classList.add("anim_in");
          }
        },
        index * 100 < 400 ? index * 100 : 400
      );
    }
    return () => {
      isMounted = false;
      clearTimeout(animTimeout.current);
    };
  }, [evalCardContainerElem, index]);
  const getPercFromEvalNumberAndIterations = (evalNn, iterations) => {
    return Math.round((evalNn / iterations) * 25);
  };
  const exportCardBilan = (outputType) => {
    let bilanTabs = document.querySelector(".bilan_tabs");
    let chartToolBars = document.querySelectorAll(".apexcharts-toolbar");
    let evalCardContainers = document.querySelectorAll(".eval_card_container");
    let commentsLabel = document.querySelectorAll(".comments_label");
    let unwantedLines = document.querySelectorAll(".apexcharts-ycrosshairs");
    let progressBars = document.querySelectorAll(".MuiLinearProgress-bar");
    let exportBtns = document.querySelectorAll(".export_btn");
    if (commentsLabel) {
      commentsLabel.forEach((label) => {
        label.style.display = "none";
      });
    }
    if (unwantedLines) {
      unwantedLines.forEach((line) => {
        line.style.display = "none";
      });
    }
    if (bilanTabs) {
      bilanTabs.style.display = "none";
    }
    if (chartToolBars) {
      chartToolBars.forEach((chartToolBar) => {
        chartToolBar.style.display = "none";
      });
    }
    if (exportBtns) {
      exportBtns.forEach((exportBtn) => {
        exportBtn.style.display = "none";
      });
    }
    if (evalCardContainers) {
      evalCardContainers.forEach((evalCardContainer) => {
        evalCardContainer.style.border = "1px solid rgba(0,0,0,0.2)";
      });
    }
    if (progressBars) {
      progressBars.forEach((progressBar) => {
        progressBar.style.transition = "none";
        progressBar.transformStyleBeforeExport = progressBar.style.transform;
        let transformStringNumber =
          progressBar.transformStyleBeforeExport.replace(/^\D+/g, "");

        progressBar.style.width =
          100 -
          parseInt(
            transformStringNumber.length > 0 ? transformStringNumber : 0
          ) +
          "%";
        progressBar.style.transform = "";
      });
    }
    if (evalCardContainerElem.current) {
      if (outputType === "pdf") {
        var worker = window
          .html2pdf()
          // .set({
          //   jsPDF: {
          //     unit: "px",
          //     format: [
          //       evalCardContainerElem.current.offsetHeight*1.2,
          //       evalCardContainerElem.current.offsetWidth*1.2,

          //     ],
          //     hotfixes: ["px_scaling"],
          //   },
          // })
          .from(evalCardContainerElem.current)
          .save(
            groupeTitre +
              "_" +
              skillName +
              (evaluateurName ? "evalué_par_" + evaluateurName : "_total") +
              ".pdf"
          );
      } else {
        var worker = window
          .html2pdf()
          .set({
            jsPDF: { unit: "mm", format: [100, 200], orientation: "portrait" },
          })
          .from(evalCardContainerElem.current)
          .outputImg("datauri")
          .then((test) => {
            let link = document.createElement("a");
            link.href = test;
            link.target = "_blank";
            link.download =
              groupeTitre +
              "_" +
              skillName +
              (evaluateurName ? "evalué_par_" + evaluateurName : "_total") +
              ".jpeg";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          });
      }
    }
    if (commentsLabel) {
      setTimeout(() => {
        commentsLabel.forEach((label) => {
          label.style.display = "";
        });
      }, 100);
    }
    if (bilanTabs) {
      setTimeout(() => {
        bilanTabs.style.display = "";
      }, 100);
    }
    if (exportBtns) {
      setTimeout(() => {
        exportBtns.forEach((exportBtn) => {
          exportBtn.style.display = "";
        });
      }, 100);
    }
    if (chartToolBars) {
      setTimeout(() => {
        chartToolBars.forEach((chartToolBar) => {
          chartToolBar.style.display = "flex";
        });
      }, 100);
    }
    if (progressBars) {
      setTimeout(() => {
        progressBars.forEach((progressBar) => {
          progressBar.style.width = "100%";
          progressBar.style.transition = "transform 0.3s";
          progressBar.style.transform = progressBar.transformStyleBeforeExport;
        });
      }, 100);
    }
    if (evalCardContainers) {
      setTimeout(() => {
        evalCardContainers.forEach((evalCardContainer) => {
          evalCardContainer.style.border = "none";
        });
      }, 100);
    }
  };
  return (
    <div ref={evalCardContainerElem} className="eval_card_container skill_card">
      <div className="skill_card_name">{skillName}</div>
      <IconButton
        className="to_pdf_btn  export_btn"
        onClick={() => {
          exportCardBilan("pdf");
        }}
      >
        <PictureAsPdfIcon style={{ color: "#0e7681" }} />
      </IconButton>
      <IconButton
        className="to_jpeg_btn  export_btn"
        onClick={() => {
          exportCardBilan("jpeg");
        }}
      >
        <PhotoLibraryIcon style={{ color: "#0e7681" }} />
      </IconButton>
      <div className="skill_card_bars_container">
        {Object.entries(results)
          .sort((a, b) =>
            getPercFromEvalNumberAndIterations(
              a[1].evalNumber,
              a[1].iterations
            ) >
            getPercFromEvalNumberAndIterations(b[1].evalNumber, b[1].iterations)
              ? -1
              : 1
          )
          .map((keyAndValue, index) => (
            <NameAndBarVisual
              key={keyAndValue[0] + skillName}
              isLast={index === Object.keys(results).length - 1}
              name={keyAndValue[0]}
              percentage={Math.round(
                (keyAndValue[1].evalNumber / keyAndValue[1].iterations) * 25
              )}
            />
          ))}
      </div>
    </div>
  );
}
