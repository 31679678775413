import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
const useStyles = makeStyles({
  addGameBtnContainer: {
    margin: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  addGameBtn: {
    transition: "transform 0.15s ease-in-out",

    background: "#009BE5",
    "& .MuiSvgIcon-root": {
      // marginLeft: 10,
      color: "white",
    },
    "&:hover": {
      background: "#3F51B5",
      transform: "scale(1.05)",
    },
  },
});

export default function AddGameCard({ setGames }) {
  const classes = useStyles();
  const getJeuVideName = (existingGames) => {
    let gameNb = 1;
    let newName = "Jeu " + gameNb;
    while (existingGames.find((_game) => _game.name === newName)) {
      gameNb++;
      newName = "Jeu " + gameNb;
    }
    return newName;
  };
  const addGame = () => {
    setGames((prv) => [...prv, { name: getJeuVideName(prv), softSkills: [] }]);
  };
  return (
    <Tooltip
      placement="bottom"
      arrow
      TransitionComponent={Zoom}
      title="Ajouter un jeu"
    >
      <div className={classes.addGameBtnContainer}>
        <Fab
          size="small"
          onClick={addGame}
          className={classes.addGameBtn}
          color="primary"
          aria-label="add"
        >
          <AddIcon />
        </Fab>
      </div>
    </Tooltip>
  );
}
