import { useState, useEffect } from "react";

export function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  let timeout = null;

  const throttle = (func, delay) => {
    let inProgress = false;
    return (...args) => {
      if (inProgress) {
        return;
      }
      inProgress = true;
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        func(...args);
        inProgress = false;
      }, delay);
    };
  };
  const handleResize = throttle(() => {
    setWindowSize({ width: window.innerWidth, height: window.innerHeight });
  }, 200);
  useEffect(() => {
    setWindowSize({ width: window.innerWidth, height: window.innerHeight });
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return windowSize;
}
