import React, { useEffect, useContext } from "react";
import { UserContext, UserRoleContext } from "../lib/context";
import { roles } from "../lib/roles";
import firebase from "../lib/firebase";
import { makeStyles } from "@material-ui/core/styles";
import { useWindowSize } from "../lib/hooks";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import CategoryIcon from "@material-ui/icons/Category";
import GroupeView from "./groupesview";
import SuperAdminPage from "./superadminpage";
import GererJeux from "./gererjeux";
import Evaluation from "./evaluation";
import Bilan from "./bilan";
import AjouterGroupe from "./ajoutergroupe";
import SwitchChart from "./switchchart";
import DecoDialog from "./decodialog";
import FilterParticipantsInput from "./filterparticipantsinput";
import EvaluateurNameAndEditBtn from "./evaluateurnameandeditbtn";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import Container from "@material-ui/core/Container";
import AddBoxIcon from "@material-ui/icons/AddBox";
import DashboardIcon from "@material-ui/icons/Dashboard";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import IconButton from "@material-ui/core/IconButton";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
import RefreshIcon from "@material-ui/icons/Refresh";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import PhotoLibraryIcon from "@material-ui/icons/PhotoLibrary";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
// import Backdrop from "@material-ui/core/Backdrop";
// import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: "100vh",
    "& .MuiAppBar-root": {
      position: "fixed",
      top: 0,
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  mainContainer: {
    position: "absolute",
    left: 0,
    width: "100%",
    minHeight: "calc(100% - 130px)",
    // overflowY: "auto",
    overflowX: "hidden",
    color: "#6c6c6c",
    // padding: 10,
    paddingBottom: 60,
    marginTop: 70,
  },
  smallHeightMainContainer: {
    paddingBottom: 45,
    marginTop: 40,
    minHeight: "calc(100vh - 90px)",
  },
  flex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  flexCol: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  bottomNav: {
    background: "#18202C",
    borderTop: "solid 1px rgba(0,0,0,0.25)",
    // boxShadow: "0px -1px 9px 0px rgba(0,0,0,0.55)",
    position: "fixed",
    zIndex: 10000,
    bottom: 0,
    width: "100%",
    height: 60,
    maxHeight: 60,
    "& button": {
      maxWidth: 250,
      padding: 0,
    },
    "& .MuiButtonBase-root": {
      color: "#a3a3a3",
    },
    "& .Mui-selected": {
      color: "#4FC3F7",
      padding: 0,
      fontSize: 12,
    },
  },
  smallHeightBottomNav: {
    background: "rgba(255,255,255,0.98)",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px -2px 8px 0px",
    border: "none",
    height: 45,
    maxHeight: 45,
  },
  toolBar: {
    position: "relative",
    "& button": {
      color: "white",
      marginRight: 8,

      [theme.breakpoints.down("xs")]: {
        marginRight: 0,
        paddingLeft: 0,
      },
    },
  },
  smallHeightToolBar: {
    maxHeight: "45px !important",
    minHeight: "45px !important",
  },
  decoBtn: {
    fontSize: "12px",
  },
  appBar: {
    justfifyContent: "space-between",
    background: "#24292E",
    color: "white",
    position: "fixed",
    maxHeight: 70,
    minHeight: 60,
    zIndex: 1000,
  },
  smallHeightAppBar: {
    background: "rgba(255,255,255,0.9)",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    maxHeight: "45px !important",
    minHeight: "45px !important",
  },
  logo: {
    width: 100,
    marginLeft: "auto",
    [theme.breakpoints.down("xs")]: {
      width: 60,
    },
  },
  logoSmallHeight: {
    width: 70,
  },
  floatingToolContainer: {
    // position: "absolute",
    // left: "50%",
    // transform: "translateX(-50%)",
    margin: "auto",
  },
  centeredToolContainer: {
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)",
    // margin:"auto",
  },
  floatRightToolContainer: {
    margin: "auto",
    marginRight: 0,
  },
}));

export default function MainApp({ setRole }) {
  const classes = useStyles();
  const auth = firebase.auth();
  const db = firebase.firestore();
  const userRole = useContext(UserRoleContext);
  const user = useContext(UserContext);
  const windowSize = useWindowSize();
  const [bottomNavValue, setBottomNavValue] = React.useState(1);
  const [filterName, setFilterName] = React.useState("");
  const [groupeData, setGroupeData] = React.useState(null);
  const [groupToModify, setGroupToModify] = React.useState(null);
  const [evaluationActive, setEvaluationActive] = React.useState(false);
  const [bilanActive, setBilanActive] = React.useState(false);
  const [evalIsBeforePage, setEvalIsBeforePage] = React.useState(false);
  const [isFullScreen, setIsFullScreen] = React.useState(false);
  const [chartType, setChartType] = React.useState("radar");
  const [refreshRdm, setRefreshRdm] = React.useState(0);
  const [typeDeBilan, setTypeDeBilan] = React.useState("cartes_individuelles");
  // const [backdropOpen, setBackdropOpen] = React.useState(false);
  const [decoDialogOpen, setDecoDialogOpen] = React.useState(false);

  const logout = async () => {
    await auth.signOut();
    console.log("Signed out");
  };
  const evaluateGroupe = (grData) => {
    setGroupeData(grData);
    setEvaluationActive(true);
    setBilanActive(false);
    setBottomNavValue(-1);
  };
  const showBilan = (grData, fromEval) => {
    setGroupeData(grData);
    setBilanActive(true);
    setEvaluationActive(false);
    setBottomNavValue(-1);
    if (fromEval) {
      setEvalIsBeforePage(true);
    }
  };
  const refreshData = async () => {
    try {
      let userInfo = await db.collection("Utilisateurs").doc(user.uid).get();
      if (userInfo && userInfo.data) {
        userInfo = userInfo.data();
        if (typeof userInfo.role === "number" && userInfo.role !== userRole) {
          setRole(userInfo.role);
        }
      }
      setRefreshRdm(Math.random());
    } catch (error) {
      console.log(error);
    }
  };
  const stopEvaluation = () => {
    setGroupeData(null);
    setEvaluationActive(false);
  };
  const handleBottomNavChange = (event, newValue) => {
    if (newValue !== 0) {
      stopEvaluation();
      setEvaluationActive(false);
      setBilanActive(false);
      setEvalIsBeforePage(false);
      setBottomNavValue(newValue);
    }
    setGroupToModify(null);
  };
  const handleBack = () => {
    if (bottomNavValue === 2) {
      setBottomNavValue(1);
      setEvaluationActive(false);
      setBilanActive(false);
    }
    if (evaluationActive) {
      setBottomNavValue(1);
      setEvaluationActive(false);
      setBilanActive(false);
    } else if (bilanActive) {
      if (evalIsBeforePage) {
        setBottomNavValue(-1);
        setEvaluationActive(true);
        setBilanActive(false);
      } else {
        setBottomNavValue(1);
        setEvaluationActive(false);
        setBilanActive(false);
      }
    }
    setEvalIsBeforePage(false);
  };

  // const onOnlineChange = () => {
  //   if (navigator.onLine) {
  //     setBackdropOpen(false);
  //   } else {
  //     setBackdropOpen(true);
  //   }
  // };
  // useEffect(() => {
  //   window.addEventListener("offline", onOnlineChange);
  //   window.addEventListener("online", onOnlineChange);
  //   return () => {
  //     window.removeEventListener("offline", onOnlineChange);
  //     window.removeEventListener("online", onOnlineChange);
  //   };
  // }, []);
  const [smallWindowHeight, setSmallWindowHeight] = React.useState(
    windowSize && windowSize.height < 600
  );
  const [smallWindowWidth, setSmallWindowWidth] = React.useState(
    windowSize && windowSize.width < 420
  );
  useEffect(() => {
    setSmallWindowHeight(windowSize && windowSize.height < 680);
    setSmallWindowWidth(windowSize && windowSize.width < 420);
  }, [windowSize]);

  const handleFullscreen = (e) => {
    if (document.fullscreenElement) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        /* Firefox */
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        /* IE/Edge */
        document.msExitFullscreen();
      }
      setIsFullScreen(false);
    } else {
      if (document.body.requestFullscreen) {
        document.body.requestFullscreen();
      } else if (document.body.mozRequestFullScreen) {
        /* Firefox */
        document.body.mozRequestFullScreen();
      } else if (document.body.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        document.body.webkitRequestFullscreen();
      } else if (document.body.msRequestFullscreen) {
        /* IE/Edge */
        document.body.msRequestFullscreen();
      }
      setIsFullScreen(true);
    }
  };

  const exportBilan = (outputType, withoutComment) => {
    let bilanTabs = document.querySelector(".bilan_tabs");
    let secondaryBilanTabs = document.querySelector(".secondary_bilan_tabs");
    let chartToolBars = document.querySelectorAll(".apexcharts-toolbar");
    let evalCardContainers = document.querySelectorAll(".eval_card_container");
    let commentsLabel = document.querySelectorAll(".comments_label");
    let unwantedLines = document.querySelectorAll(".apexcharts-ycrosshairs");
    let progressBars = document.querySelectorAll(".MuiLinearProgress-bar");
    let groupeTitreElem = document.querySelector(".groupe_titre");
    let exportBtns = document.querySelectorAll(".export_btn");
    let faiblessesSwitch = document.querySelector(".view_faiblesses_switch");
    let commentsContainerElems = document.querySelectorAll(
      ".comments_for_export"
    );
    let exportFunc = (withComment) => {
      if (groupeTitreElem) {
        groupeTitreElem.style.display = "block";
      }
      if (commentsLabel) {
        commentsLabel.forEach((label) => {
          label.style.display = "none";
        });
      }
      if (withComment && commentsContainerElems) {
        commentsContainerElems.forEach((commentsContainer) => {
          commentsContainer.style.display = "flex";
        });
      }
      if (exportBtns) {
        exportBtns.forEach((exportBtn) => {
          exportBtn.style.display = "none";
        });
      }
      if (faiblessesSwitch) {
        faiblessesSwitch.style.display = "none";
      }
      if (unwantedLines) {
        unwantedLines.forEach((line) => {
          line.style.display = "none";
        });
      }
      if (bilanTabs) {
        bilanTabs.style.display = "none";
      }
      if (secondaryBilanTabs) {
        secondaryBilanTabs.style.display = "none";
      }
      if (chartToolBars) {
        chartToolBars.forEach((chartToolBar) => {
          chartToolBar.style.display = "none";
        });
      }
      if (evalCardContainers) {
        evalCardContainers.forEach((evalCardContainer) => {
          evalCardContainer.style.border = "1px solid rgba(0,0,0,0.2)";
        });
      }
      if (progressBars) {
        progressBars.forEach((progressBar) => {
          progressBar.style.transition = "none";
          progressBar.transformStyleBeforeExport = progressBar.style.transform;
          let transformStringNumber =
            progressBar.transformStyleBeforeExport.replace(/^\D+/g, "");

          progressBar.style.width =
            100 -
            parseInt(
              transformStringNumber.length > 0 ? transformStringNumber : 0
            ) +
            "%";
          progressBar.style.transform = "";
        });
      }
      let bilanView = document.querySelector(".bilan_view");
      if (bilanView) {
        if (outputType === "pdf") {
          var worker = window
            .html2pdf()
            .set({
              pagebreak: { avoid: "div" },
            })
            .from(bilanView)
            .save(
              (groupeData && groupeData.data && groupeData.data.titre
                ? groupeData.data.titre
                : "") +
                "_total_" +
                typeDeBilan
            );
        } else {
          var worker = window
            .html2pdf()
            .from(bilanView)
            .outputImg("datauri")
            .then((test) => {
              let link = document.createElement("a");
              link.href = test;
              link.target = "_blank";
              link.download =
                (groupeData && groupeData.data && groupeData.data.titre
                  ? groupeData.data.titre
                  : "") +
                "_total_" +
                typeDeBilan +
                ".jpeg";
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            });
        }
      }
      if (groupeTitreElem) {
        setTimeout(() => {
          groupeTitreElem.style.display = "none";
        }, 100);
      }
      if (commentsLabel) {
        setTimeout(() => {
          commentsLabel.forEach((label) => {
            label.style.display = "";
          });
        }, 100);
      }
      if (withComment && commentsContainerElems) {
        setTimeout(() => {
          commentsContainerElems.forEach((commentsContainer) => {
            commentsContainer.style.display = "none";
          });
        }, 100);
      }
      if (faiblessesSwitch) {
        setTimeout(() => {
          faiblessesSwitch.style.display = "flex";
        }, 100);
      }
      if (exportBtns) {
        setTimeout(() => {
          exportBtns.forEach((exportBtn) => {
            exportBtn.style.display = "";
          });
        }, 100);
      }
      if (bilanTabs) {
        setTimeout(() => {
          bilanTabs.style.display = "";
        }, 100);
      }
      if (secondaryBilanTabs) {
        setTimeout(() => {
          secondaryBilanTabs.style.display = "";
        }, 100);
      }
      if (chartToolBars) {
        setTimeout(() => {
          chartToolBars.forEach((chartToolBar) => {
            chartToolBar.style.display = "flex";
          });
        }, 100);
      }
      if (progressBars) {
        setTimeout(() => {
          progressBars.forEach((progressBar) => {
            progressBar.style.width = "100%";
            progressBar.style.transition = "transform 0.3s";
            progressBar.style.transform =
              progressBar.transformStyleBeforeExport;
          });
        }, 100);
      }
      if (evalCardContainers) {
        setTimeout(() => {
          evalCardContainers.forEach((evalCardContainer) => {
            evalCardContainer.style.border = "none";
          });
        }, 100);
      }
    };
    if (commentsContainerElems.length > 0) {
      confirmAlert({
        title: "Exporter avec les commentaires ?",
        // message: "Exporter avec les commentaires ?",
        buttons: [
          {
            label: "Oui",
            onClick: () => exportFunc(true),
          },
          {
            label: "Non",
            onClick: () => exportFunc(false),
          },
        ],
      });
    } else {
      exportFunc(false);
    }
  };

  return (
    <div className={classes.root}>
      <DecoDialog
        opened={decoDialogOpen}
        setDecoDialogOpen={setDecoDialogOpen}
        logout={logout}
      />
      {/* {backdropOpen && (
        <Backdrop className={classes.backdrop} open={backdropOpen}>
          <Alert severity="info">
            {" "}
            Aucune connexion internet, en attente de reconnexion.
          </Alert>
        </Backdrop>
      )} */}

      <AppBar
        position="static"
        className={
          classes.appBar +
          " " +
          (smallWindowHeight ? classes.smallHeightAppBar : "")
        }
      >
        <Toolbar
          className={
            classes.toolBar +
            " " +
            (smallWindowHeight ? classes.smallHeightToolBar : "")
          }
        >
          {(evaluationActive || bilanActive) && (
            <IconButton onClick={handleBack}>
              <ArrowBackIcon
                style={{ color: smallWindowHeight ? "#0e7681" : "white" }}
              />
            </IconButton>
          )}
          {window.navigator.userAgent.indexOf("iPhone") === -1 && (
            <Tooltip
              placement="bottom"
              arrow
              TransitionComponent={Zoom}
              title={
                isFullScreen
                  ? "Réduire (ECHAP si cela ne fonctionne pas)"
                  : "Plein écran"
              }
            >
              <IconButton onClick={handleFullscreen}>
                {isFullScreen ? (
                  <FullscreenExitIcon
                    style={{ color: smallWindowHeight ? "#0e7681" : "white" }}
                  />
                ) : (
                  <FullscreenIcon
                    style={{ color: smallWindowHeight ? "#0e7681" : "white" }}
                  />
                )}
              </IconButton>
            </Tooltip>
          )}
          {(bottomNavValue === 1 || evaluationActive || bilanActive) && (
            <IconButton onClick={refreshData}>
              <RefreshIcon
                style={{ color: smallWindowHeight ? "#0e7681" : "white" }}
              />
            </IconButton>
          )}
          {bilanActive && (
            <>
              <IconButton
                onClick={() => {
                  exportBilan("pdf");
                }}
              >
                <PictureAsPdfIcon
                  style={{ color: smallWindowHeight ? "#0e7681" : "white" }}
                />
              </IconButton>
              <IconButton
                onClick={() => {
                  exportBilan("jpeg");
                }}
              >
                <PhotoLibraryIcon
                  style={{ color: smallWindowHeight ? "#0e7681" : "white" }}
                />
              </IconButton>
            </>
          )}
          <div
            className={
              bilanActive
                ? smallWindowWidth
                  ? classes.floatRightToolContainer
                  : classes.floatingToolContainer
                : evaluationActive && smallWindowWidth
                ? classes.floatRightToolContainer
                : classes.centeredToolContainer
            }
          >
            {bilanActive ? (
              typeDeBilan === "cartes_individuelles" ? (
                <SwitchChart
                  chartType={chartType}
                  setChartType={setChartType}
                  setRefreshRdm={setRefreshRdm}
                  darkColor={smallWindowHeight}
                />
              ) : (
                ""
              )
            ) : evaluationActive ? (
              <FilterParticipantsInput
                setFilterName={setFilterName}
                smallWindowHeight={smallWindowHeight}
              />
            ) : (
              <EvaluateurNameAndEditBtn darkColor={smallWindowHeight} />
            )}
          </div>
          {(bilanActive || evaluationActive) && !smallWindowWidth && (
            <EvaluateurNameAndEditBtn
              darkColor={smallWindowHeight}
              hideEditBtn={bilanActive}
            />
          )}
          {!bilanActive && !evaluationActive && (
            <img
              className={
                classes.logo +
                " " +
                (smallWindowHeight ? classes.logoSmallHeight : "")
              }
              alt="Synergie family logo"
              src={
                process.env.PUBLIC_URL +
                "/img/logo_synergie_" +
                (smallWindowHeight ? "green" : "family") +
                ".svg"
              }
            />
          )}
        </Toolbar>
      </AppBar>
      <Container
        className={
          classes.mainContainer +
          " " +
          (!bilanActive && !evaluationActive ? classes.flex : "") +
          " " +
          (smallWindowHeight ? classes.smallHeightMainContainer : "")
        }
        disableGutters={true}
        maxWidth="xl"
      >
        {evaluationActive && groupeData ? (
          <Evaluation
            groupeId={groupeData.id}
            showBilan={showBilan}
            refreshRdm={refreshRdm}
            filterName={filterName}
          />
        ) : bilanActive && groupeData ? (
          <Bilan
            groupeId={groupeData.id}
            groupeTitre={
              groupeData && groupeData.data ? groupeData.data.titre : "Session"
            }
            groupeDate={
              groupeData && groupeData.data ? groupeData.data.date : ""
            }
            refreshRdm={refreshRdm}
            chartType={chartType}
            typeDeBilan={typeDeBilan}
            setTypeDeBilan={setTypeDeBilan}
          />
        ) : bottomNavValue === 1 ? (
          <GroupeView
            evaluateGroupe={evaluateGroupe}
            showBilan={showBilan}
            handleBottomNavChange={handleBottomNavChange}
            setBottomNavValue={setBottomNavValue}
            refreshRdm={refreshRdm}
            setGroupToModify={setGroupToModify}
          />
        ) : bottomNavValue === 2 ? (
          <AjouterGroupe
            setBottomNavValue={setBottomNavValue}
            groupToModify={groupToModify}
          />
        ) : bottomNavValue === 3 ? (
          <GererJeux />
        ) : bottomNavValue === 4 ? (
          <SuperAdminPage />
        ) : (
          ""
        )}
      </Container>
      {((!smallWindowHeight && evaluationActive) || !evaluationActive) &&
        !(bilanActive && smallWindowHeight) && (
          <BottomNavigation
            value={bottomNavValue}
            onChange={handleBottomNavChange}
            showLabels
            className={
              classes.bottomNav +
              " " +
              (smallWindowHeight ? classes.smallHeightBottomNav : "")
            }
          >
            <BottomNavigationAction
              onClick={() => {
                setDecoDialogOpen(true);
              }}
              label="Déconnecter"
              icon={<ExitToAppIcon />}
            />

            <BottomNavigationAction
              className="grp_btn"
              label="Sessions"
              icon={<DashboardIcon />}
            />
            {userRole <= roles.ADMIN && (
              <BottomNavigationAction
                className="create_grp_btn"
                label="Créer une session"
                icon={<AddBoxIcon />}
              />
            )}
            {userRole <= roles.ADMIN && (
              <BottomNavigationAction
                className="manage_games_btn"
                label="Jeux pré-enregistrés"
                icon={<CategoryIcon />}
              />
            )}
            {userRole === roles.SUPER_ADMIN && (
              <BottomNavigationAction
                className="manage_users_btn"
                label="Gérer les utilisateurs"
                icon={<SupervisorAccountIcon />}
              />
            )}
          </BottomNavigation>
        )}
    </div>
  );
}
