import React, { useContext, useState } from "react";
import { UserContext } from "../lib/context";
import firebase from "../lib/firebase";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import EditIcon from "@material-ui/icons/Edit";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  editNameIcon: {
    marginLeft: "4px",
    padding: "13px",
    cursor: "pointer",
    width: "48px",
    height: "48px",
    borderRadius: "50%",
    transition: "background-color 0.2s ease-in-out",
    // "&:hover": {
    //   backgroundColor: "#24A0A0",
    // },
  },
  iconDark: {
    color: "green",
    width: "44px",
    height: "44px",
  },
}));
export default function FormDialog({ setEditedUserName, darkColor }) {
  const classes = useStyles();
  const user = useContext(UserContext);
  const db = firebase.firestore();
  const [open, setOpen] = useState(false);
  const [newName, setNewName] = useState("");
  const [loadingConfirm, setLoadingConfirm] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleConfirm = async () => {
    try {
      if (newName.length > 0) {
        setLoadingConfirm(true);
        await user.updateProfile({
          displayName: newName,
        });
        await db.collection("Utilisateurs").doc(user.uid).update({
          name: newName,
        });
        const thisEvaluateurEvalsDocs = await db
          .collection("Evaluations")
          .where("evaluateurUid", "==", user.uid)
          .get();
        var batch = db.batch();
        thisEvaluateurEvalsDocs.forEach((doc) => {
          batch.update(doc.ref, { evaluateurName: newName });
        });
        await batch.commit();

        setEditedUserName(newName);
        setLoadingConfirm(false);
        handleClose();
      }
    } catch (error) {
      console.log(error);
      setLoadingConfirm(false);
    }
  };
  const handleInputChange = (e) => {
    setNewName(e.target.value);
  };

  return (
    <div className={classes.root}>
      <Tooltip
        placement="bottom"
        arrow
        TransitionComponent={Zoom}
        title="Modifier votre nom"
      >
        <EditIcon
          className={classes.editNameIcon + " " + (darkColor ? classes.iconDark : "")}
          onClick={() => {
            setOpen(true);
          }}
        />
      </Tooltip>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent>
          <DialogContentText>
            Veuillez choisir un nom d'évaluateur.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Nom d'évaluateur"
            type="text"
            fullWidth
            onChange={handleInputChange}
            autoComplete="off"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Annuler
          </Button>
          <Button
            disabled={loadingConfirm}
            onClick={handleConfirm}
            color="primary"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
