import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";

const Chart = ({ results, pName, type }) => {
  const sortedResultsEntries = Object.entries(results).sort(
    ([skill1, result1], [skill2, result2]) => (skill1 < skill2 ? -1 : 1)
  );
  const sortedResultsKeys = sortedResultsEntries.map((entry) => entry[0]);
  const sortedResultsValues = sortedResultsEntries.map((entry) => entry[1]);

  const [options] = useState({
    chart: {
      
      sparkline: {
        enabled: type === "radar",
      },

      fontFamily: "Roboto",
      toolbar: {
        show: false,
        offsetY: 0,
        tools: {
          download: true,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
          customIcons: [],
        },
      },
      // height: "100%",
      type: type,
      // offsetY: type === "radar" ? -10 : 0,
      // offsetX: type === "radar" ? 0 : 20,
      animations: {
        enabled: true,
        easing: "easeout",
        speed: 600,
        animateGradually: {
          enabled: true,
          delay: 150,
        },
        dynamicAnimation: {
          enabled: true,
          speed: 600,
        },
      },
    },
    fill: {
      opacity: type === "bar" ? 0.4 : 0.4,
      colors: type === "bar" ? ["#0e7681"] : ["#0e7681"],
    },
    stroke: {
      show: true,
      width: type === "bar" ? 1 : 2,
      colors: type === "bar" ? ["#0e7681"] : ["#0e7681"],
      dashArray: 0,
    },

    markers: {
      size: 3,
      colors: ["#0e7681"],
      strokeColors: type === "bar" ? ["#3E9CC4"] : ["#0e7681"],
      // strokeWidth: 2,
      // strokeOpacity: 0.5,
      strokeDashArray: 0,
      fillOpacity: 1,
      discrete: [],
      shape: "circle",
      radius: 2,
      showNullDataPoints: true,
      hover: {
        size: undefined,
        sizeOffset: 3,
      },
    },
    legend: { show: false },
    plotOptions: {
      bar: {
        columnWidth: "50%",
        distributed: true,
      },
      radar: {
        offsetX: 0,
        offsetY: 0,
        polygons: {
          strokeColors: "rgba(36, 42, 46, 0.267)",
          strokeWidth: 2,
          connectorColors: "rgba(36, 42, 46, 0.167)",
          fill: {
            colors: ["#ffffff00"],
          },
        },
      },
    },

    tooltip: {
      enabled: true,
      style: {
        fontSize: "12px",
        fontFamily: "Roboto",
      },
      y: {
        formatter: (label, series) => {
          if (type === "bar") {
            try {
              return (
                sortedResultsKeys[series.dataPointIndex] + ": " + label + "%"
              );
            } catch (error) {}
          }
          return label + "%";
        },
        title: {
          formatter: (seriesName) => (type === "bar" ? "" : "score: "),
        },
      },
      x: {
        show: type === "bar" ? false : true,
        title: {
          formatter: (seriesName) => (type === "bar" ? seriesName : "score: "),
        },
        formatter: (label) => (type === "bar" ? label : label.join(" ")),
      },

      marker: {
        show: false,
      },
    },

    yaxis: {
      show: false,
      min: 0,
      max: 100,
      tickAmount: type === "bar" ? 3 : 2,
    },
    dataLabels: {
      enabled: type === "bar" ? false : false,
      style: {
        fontSize: "10px",
        // colors: ["#0e7681", "#0e7681"],
        fontFamily: "Roboto",
        fontWeight: "bold",
      },
      offsetX: -1,
      dropShadow: {
        enabled: true,
        top: 0,
        left: 0,
        blur: 1,
        color: "#000",
        opacity: 0.45,
      },
    },
    title: {
      text: pName,
      color: "#0e7681",
      style: {
        fontSize: "16px",
        fontWeight: "light",
        fontFamily: "Roboto",
        color: "#263238",
      },
    },
    xaxis: {
      categories: sortedResultsKeys.map((label) => {
        // if (type === "bar") {
        //   return label;
        // }
        let labelSplited = label.split(" ");
        if (labelSplited.length > 2) {
          labelSplited = [
            labelSplited[0],
            label.substring(label.indexOf(" ") + 1),
          ];
        }
        return labelSplited;
      }),
      tickPlacement: "on",

      labels: {
        show: true,
        offsetY: 0,
        // minHeight: 100,
        // maxHeight: 200,
        rotateAlways: true,
        style: {
          cssClass: "apexcharts-xaxis-label",
          colors: [
            "#0e7681",
            "#0e7681",
            "#0e7681",
            "#0e7681",
            "#0e7681",
            "#0e7681",
            "#0e7681",
            "#0e7681",
            "#0e7681",
            "#0e7681",
            "#0e7681",
            "#0e7681",
          ],
          fontSize: "9px",
          fontFamily: "Roboto",
          trim: false,
        },
      },
    },
  });
  const [series] = useState([
    {
      data: sortedResultsValues.map(
        (result) =>
          Math.round(
            ((result.evalNumber) / result.iterations) * (25)
          )
        // 0 -> non évalué
        // 1 -> 25%
        // 2 -> 50%
        // 3 -> 75%
        // 4 -> 100%
      ),
    },
  ]);

  return (
    <ReactApexChart
      options={options}
      series={series}
      type={type}
      height={"95%"}
      // width={type === "radar" ? "100%" : "80%"}
    />
  );
};

export default Chart;
