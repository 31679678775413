import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import firebase from "../lib/firebase";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import IconButton from "@material-ui/core/IconButton";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import CircularProgress from "@material-ui/core/CircularProgress";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";

const useStyles = makeStyles((theme) => ({
  root: { marginLeft: 20 },
  highlighted: {
    fontSize: 18,
    fontWeight: "bold",
    color: "black",
  },
  deleteBtn: {
    color: "red",
  },
}));

export default function DeleteGroupDialog({
  softSkillName,
  availableSoftSkillList,
  setAvailableSoftSkillList,
}) {
  const classes = useStyles("");
  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const db = firebase.firestore();

  const deleteSoftskill = async () => {
    try {
      if (availableSoftSkillList.includes(softSkillName)) {
        setIsLoading(true);
        let clonedAvailableSoftSkillList = [...availableSoftSkillList];
        clonedAvailableSoftSkillList =  clonedAvailableSoftSkillList.filter((skill) => skill !== softSkillName);
        await db
          .collection("Softskills")
          .doc("softskill-list")
          .update({ list: clonedAvailableSoftSkillList });
          setIsLoading(false)
        setAvailableSoftSkillList(clonedAvailableSoftSkillList);

      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {

    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <Tooltip
        placement="right"
        arrow
        TransitionComponent={Zoom}
        title="Supprimer ce soft skill"
      >
        <IconButton onClick={handleClickOpen} size="medium">
          <DeleteForeverIcon />
        </IconButton>
      </Tooltip>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Voulez vous supprimer ce soft skill:
            <span className={classes.highlighted}>{" " + softSkillName}</span>?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {isLoading ? (
            <CircularProgress disableShrink />
          ) : (
            <>
              <Button onClick={handleClose} color="primary">
                ANNULER
              </Button>
              <Button onClick={deleteSoftskill} color="primary" autoFocus>
                OK
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
