import React from "react";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  commentContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    flexDirection: "column",
    background: "#eff2f5",
    padding: 10,
    borderRadius: 10,
    margin: "3px 0",
    width: "100%",
  },
  author: {
    fontWeight: 800,
    fontSize: "0.9rem",
  },
  message: {
    fontSize: "1rem",
    fontWeight: 100,
    color: "#626262",
  },
  dateSent: {
    fontWeight: 100,
    fontStyle: "italic",
    fontSize: "0.8rem",
  },
}));

export default function ControlledOpenSelect({ comment }) {
  const classes = useStyles();
  return (
    <div className={classes.commentContainer}>
      <div className={classes.author}>
        {comment.author}{" "}
        <span className={classes.dateSent}>
          {new Date(comment.dateSent).toLocaleDateString("fr-FR", {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
          })}
        </span>
      </div>
      <div className={classes.message}>{comment.message}</div>
    </div>
  );
}
