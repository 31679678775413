import React, { useContext, useEffect, useState } from "react";
import firebase from "../lib/firebase";
import { makeStyles } from "@material-ui/core/styles";
import EditableGameCard from "./editablegamecard";
import Button from "@material-ui/core/Button";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles({
  root: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "stretch",
    flexWrap: "wrap",
  },
  createGameBtn: {
    width: "90%",
    maxWidth:"500px",
    height: 80,
    fontSize: 22,
    borderRadius: "20px",
    background: "#ebebeb",
    boxShadow: "20px 20px 60px #c8c8c8, -20px -20px 60px #ffffff",
    "&:hover": {
      color: "white",
      background: "linear-gradient( 136deg,#f14f83 0%,#be1c50 100%)",
    },
  },
  addGameBtnContainer: {
    margin: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

  },
  addGameBtn: {
    transition: "transform 0.15s ease-in-out",
    background: "#009BE5",
    "& .MuiSvgIcon-root": {
      // marginLeft: 10,
      color: "white",
    },
    "&:hover": {
      background: "#3F51B5",
      transform: "scale(1.05)",
    },
  },
});

export default function GererJeux() {
  const classes = useStyles();
  const db = firebase.firestore();
  const [games, setGames] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const savePremadeGames = async (_games) => {
    console.log("SAVING...");
    try {
      if (_games && Array.isArray(_games)) {
        await db.collection("Games").doc("games-list").set({ games: _games });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const addGame = () => {
    setGames((prv) => {
      let modifiedGames = [
        ...prv,
        { name: "Jeu " + (prv.length + 1), softSkills: [] },
      ];
      savePremadeGames(modifiedGames);
      return modifiedGames;
    });
  };
  useEffect(() => {
    let isMounted = true;
    (async () => {
      try {
        let savedGames = await db.collection("Games").doc("games-list").get();
        if (isMounted) {
          savedGames =
            savedGames.data() && savedGames.data().games
              ? savedGames.data().games
              : [];
          if (Array.isArray(savedGames)) {
            setGames(savedGames);
          }
          setIsLoading(false);
        }
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    })();

    return () => {
      isMounted = false;
    };
  }, [db]);

  return (
    <div className={classes.root}>
      {isLoading ? (
        <CircularProgress />
      ) : games.length > 0 ? (
        <>
          {games.map((game, index) => (
            <EditableGameCard
              key={game.name + Math.random()}
              index={index}
              game={game}
              games={games}
              setGames={setGames}
              fromManageGames={true}
              savePremadeGames={savePremadeGames}
            />
          ))}
          <div className={classes.addGameBtnContainer}>
            <Fab
              size="small"
              onClick={addGame}
              className={classes.addGameBtn}
              color="primary"
              aria-label="add"
            >
              <AddIcon />
            </Fab>
          </div>
        </>
      ) : (
        <Button
          className={classes.createGameBtn}
          variant="contained"
          onClick={addGame}
        >
          Ajouter un jeu pré-enregistré
        </Button>
      )}
    </div>
  );
}
