import React, { useState, useEffect, useCallback } from "react";
import firebase from "../lib/firebase";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import Rating from '@material-ui/lab/Rating';
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import CommentsWindow from "./commentswindow";

import "../styles/evaltable.css";

const StyledTableCell = withStyles((theme) => ({
  head: {
    background: "#0E7681",
    color: theme.palette.common.white,
    maxWidth: 120,
    minWidth: 120,
    padding: 12,
    fontSize: 16,

    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
      maxWidth: 80,
      minWidth: 80,
      lineHeight: 1.1,
    },
  },
  body: {
    fontSize: 14,
    height: 50,
    minHeight: 60,
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    backgroundColor: "#E0E0E0",
    padding: 10,
    height: 20,
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    boxSizing: "border-box",
    background: "transparent",
    // maxHeight: "calc(100vh - 400px)",
    width: "92vw",
    marginLeft: "4vw",
    marginRight: "4vw",
    flexGrow: 1,
    flexShrink: 0,
    marginTop: 15,

    [theme.breakpoints.up("md")]: {
      width: "90vw",
      marginLeft: "5vw",
      marginRight: "5vw",
    },
    [theme.breakpoints.up("lg")]: {
      width: "70vw",
      marginLeft: "15vw",
      marginRight: "15vw",
    },
    "& .MuiTable-root": {
      // boxShadow: "1px 1px 9px 0px rgba(0,0,0,0.5)"
    },
  },
  table: {
    // overflow: "hidden",
    height: "100%",
    background: "#E0E0E0",
    padding: 5,
    "& .MuiTableCell-body": {
      // padding: 40,
    },

    borderRadius: 5,
    "& .MuiTableCell-alignCenter:not(.MuiTableCell-head)": {
      border: "solid 3px #E0E0E0",

      borderRadius: 10,
      cursor: "pointer",
    },
    "& .MuiTableCell-body:not(.MuiTableCell-head):not(.MuiTableCell-alignCenter)":
      {
        maxWidth: 100,
        borderBottom: "none",
        padding: 5,
        marginTop: 10,
        marginLeft: 2,
        textAlign: "center",
        wordBreak: "break-word",
        fontSize: 16,
        [theme.breakpoints.down("sm")]: {
          fontSize: 12,
        },
        background: "#E0E0E0",
      },
  },
  noData: {
    width: "100vw",
    textAlign: "center",
  },
  sticky: {
    left: -12,
    background: "#E0E0E0",

    // display: "flex",
    // alignItems: "center",
    // justifyContent: "center",
    position: "-webkit-sticky",
    position: "sticky",
    zIndex: 1000,
    // width: "92%",
    // maxWidth: "92%!important",
    minWidth: "100px!important",
  },
  // participantName: {
  //   display: "inline-block",
  //   lineHeight: "100%",
  // },
  verticalCenter: {
    // height: "100%",
    display: "inline-block",
    verticalAlign:"middle",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default function EvaluationTable(props) {
  const { groupeData, jeu, evalData, evalId, filterName } = props;
  const classes = useStyles(props);
  const db = firebase.firestore();
  // useEffect(() => {
  //   if (filterName && filterName.length > 0 && evalData) {
  //     console.log(evalData);
  //   }
  // }, [filterName]);
  const getRows = useCallback(() => {
    let tempRows = [];
    for (const participantName of groupeData.participants.filter(
      (pName) => pName.toLowerCase().indexOf(filterName.toLowerCase()) !== -1
    )) {
      let thisParticipantEval = evalData.participants.find(
        (p) => p.name === participantName
      );

      let evaluation = {};
      let thisGameEval = {};
      for (const skill of jeu.softSkills) {
        if (
          thisParticipantEval &&
          thisParticipantEval[jeu.name] &&
          thisParticipantEval[jeu.name][skill]
        ) {
          thisGameEval[skill] = thisParticipantEval[jeu.name][skill];
        }
        evaluation[skill] = thisGameEval[skill];
      }
      tempRows.push({ participant: participantName, ...evaluation });
    }
    return tempRows;
  }, [groupeData, evalData, jeu, filterName]);

  const [rows, setRows] = useState(getRows());
  const [sendTimeout, setSendTimeout] = useState(null);

  useEffect(() => {
    setRows(getRows());
  }, [groupeData, getRows]);

  const evalSkill = (event, participant, index, skill) => {
    event.stopPropagation();
    let target = event.target;
    let tempRows = [...rows];
    let evalNumber = 0;
    if (isNaN(tempRows[index][skill])) {
      tempRows[index][skill] = 0;
    }
    if (tempRows[index][skill] === 4) {
      tempRows[index][skill] = 0;
      evalNumber = 0;
    } else {
      tempRows[index][skill] += 1;
      evalNumber = tempRows[index][skill];
    }

    evalData.participants.forEach((p) => {
      if (p.name === participant) {
        if (!p[jeu.name]) {
          p[jeu.name] = {};
        }
        p[jeu.name][skill] = evalNumber;
      }
    });
    let evalTextElem = target.querySelector(".eval_text");
    if (evalNumber <= 1) {
      if (evalTextElem) {
        console.log("%c%s", "color: #731d1d", "de");
        evalTextElem.innerHTML =
          evalNumber === 0
            ? "NON ÉVALULÉ"
            // : evalNumber === 1
            //   ? "PEUT MIEUX FAIRE"
            //   : evalNumber === 2
            //     ? "MOYEN"
            //     : evalNumber === 3
            //       ? "BON"
                  : "";
        evalTextElem.classList.remove("anime_text_in");
        evalTextElem.classList.add("anime_text_in");
      }
      let newClass = getColorClass(evalNumber);
      target.classList.remove("grey");
      target.classList.remove("red");
      target.classList.remove("yellow");
      target.classList.remove("green");
      try {
        void target.offsetWidth;
      } catch (e) { }
 
      target.classList.add(newClass);
    }
    setRows(tempRows);
    clearTimeout(sendTimeout);
    setSendTimeout(
      setTimeout(() => {
        sendEval(evalData, jeu);
      }, 800)
    );
  };

  const sendEval = async (_evalData, _jeu) => {
    try {
      await db.collection("Evaluations").doc(evalId).update({
        participants: evalData.participants,
      });
    } catch (error) {
      console.log(error);
    }
  };

  if (rows.length === 0) {
    return <div className={classes.noData}>Aucun participant</div>;
  }
  const getColorClass = (num) => {
    return !num
    ? "grey"
    : "green"

    // return !num
    //   ? "grey"
    //   : num === 1
    //   ? "red"
    //   : num === 2
    //   ? "yellow"
    //   : num === 3
    //   ? "green"
    //   : "";
  };
  return (
    <TableContainer component={Paper} className={classes.tableContainer}>
      <Table
        stickyHeader
        className={classes.table + " table"}
        aria-label="customized table"
        // size="small"
      >
        <TableHead>
          <TableRow>
            <StyledTableCell>{groupeData.titre}</StyledTableCell>
            {jeu.softSkills.map((skill) => (
              <StyledTableCell key={skill} align="center">
                {skill}
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <StyledTableRow key={row.participant}>
              <StyledTableCell
                component="th"
                scope="row"
                className={classes.sticky}
              >
                <div className={classes.verticalCenter}>
                  {" "}
                  <CommentsWindow
                    groupeId={groupeData.id}
                    evaluatedUserName={row.participant}
                  />
                </div>
                <div className={classes.verticalCenter}>
                  <span className={classes.participantName}>
                    {row.participant}
                  </span>
                </div>
              </StyledTableCell>
              {jeu.softSkills.map((skill) => (
                <StyledTableCell
                  key={skill + index}
                  onClick={(e) => {
                    evalSkill(e, row.participant, index, skill);
                  }}
                  className={
                    classes.touchableCell + " " + getColorClass(row[skill])
                  }
                  align="center"
                >
                  <Rating className={row[skill] ? "" : "hidden"} name="read-only" value={row[skill] ? row[skill]: 0} readOnly max={4} />
                  
                  {/* <span className="eval_text"></span> */}
                </StyledTableCell>
              ))}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
