import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

var firebaseConfig = {
  apiKey: "AIzaSyAUeHqiRaHCSI70P8_ig0y8GgvkrMD1KYw",
  authDomain: "recrut-game.firebaseapp.com",
  projectId: "recrut-game",
  storageBucket: "recrut-game.appspot.com",
  messagingSenderId: "305512388920",
  appId: "1:305512388920:web:0713f61bed0ecbe69089cd",
  measurementId: "G-SQR4N8ENQF"
  // apiKey: "AIzaSyBCixiNh4kQ8rLXkCr1Vpj2lxPDSoRXwyo",
  // authDomain: "synergie-reunion.firebaseapp.com",
  // databaseURL: "https://synergie-reunion.firebaseio.com",
  // projectId: "synergie-reunion",
  // storageBucket: "synergie-reunion.appspot.com",
  // messagingSenderId: "298770415789",
  // appId: "1:298770415789:web:23aefa0aa8eba568528624",
  // measurementId: "G-D5E1S642JG"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export default firebase;
