export const roles = {
  SUPER_ADMIN: 0,
  ADMIN: 1,
  EVALUATEUR: 2,
  GUEST: 3,
  BANNED: 4,
};
export const rolesLabel = {
  0: "Super admin",
  1: "Admin",
  2: "Évaluateur",
  3: "Invité",
  4: "Banni",
};
export const rolesColor = {
  0: "#D6096A",
  1: "#F5AD4A",
  2: "#408CCC",
  3: "#42AD8C",
  4: "red",
};
