import React  from "react";
import { makeStyles } from "@material-ui/core/styles";
import EditableGameCard from "./editablegamecard";
import AddGameCard from "./addgamecard";

const useStyles = makeStyles({
  root: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "stretch",
    flexWrap: "wrap",
  },
});

export default function GameAndSoftSkillSelector({ games, setGames }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {games.map((game, index) => (
        <EditableGameCard
          key={game.name + Math.random()}
          index={index}
          game={game}
          games={games}
          setGames={setGames}
        />
      ))}
      <AddGameCard setGames={setGames} />
    </div>
  );
}
