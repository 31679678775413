import React, { useState } from "react";
import firebase from "../lib/firebase";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles(() => ({
  root: {},

}));
export default function FormDialog({
  availableSoftSkillList,
  setAvailableSoftSkillList,
}) {
  const classes = useStyles();
  const db = firebase.firestore();
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [newSoftskill, setNewSoftskill] = useState("");
  const handleClose = () => {
    setIsLoading(false);
    setErrorMsg(null);
    setOpen(false);
  };
  const handleConfirm = async () => {
    try {
      if (!availableSoftSkillList.includes(newSoftskill)) {
        setIsLoading(true);
        let clonedAvailableSoftSkillList = [...availableSoftSkillList];
        clonedAvailableSoftSkillList.push(newSoftskill);
        await db
          .collection("Softskills")
          .doc("softskill-list")
          .set({ list: clonedAvailableSoftSkillList });
        setAvailableSoftSkillList(clonedAvailableSoftSkillList);
        handleClose();
      } else {
        setErrorMsg("Ce soft skill existe déjà");
      }
    } catch (error) {
      console.log(error);
      setErrorMsg("Erreur serveur");
      setIsLoading(false);
    }
  };
  const handleInputChange = (e) => {
    setNewSoftskill(e.target.value);
  };

  return (
    <div className={classes.root}>
      <Button
        onClick={() => {
          setOpen(true);
        }}
        color="secondary"
      >
        Créer un soft skill
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent >
          <DialogContentText>Intitulé du nouveau Soft skill.</DialogContentText>
          {errorMsg && <Alert severity="error">{errorMsg}</Alert>}
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Soft skill"
            type="text"
            fullWidth
            onChange={handleInputChange}
            autoComplete="off"
          />
        </DialogContent>
        <DialogActions>
          {isLoading ? (
            <CircularProgress disableShrink />
          ) : (
            <>
              {" "}
              <Button onClick={handleClose} color="primary">
                Annuler
              </Button>
              <Button onClick={handleConfirm} color="primary">
                OK
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
