import React from "react";
import Switch from "@material-ui/core/Switch";
import { makeStyles } from "@material-ui/core/styles";
import BarChartIcon from "@material-ui/icons/BarChart";

const useStyles = makeStyles((theme) => ({
  switchChartContainer: props=> ({
    flex: 1,
    textAlign: "center",
    padding: 0,
    margin: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& .MuiSwitch-switchBase ": {
      color: props.darkColor ? "#0e7681" : "white!important",
    },
    "& .Mui-checked": {
      color: props.darkColor ? "#0e7681" : "white!important",
    },
    "& .MuiSwitch-track": {
      backgroundColor: "white!important",
    },
    "& .MuiSwitch-track": {
      backgroundColor: props.darkColor ? "#0e7681" : "white!important",
    }
  }),
  radarIcon: {
    height: "22px",
    marginRight: 4,
  },
}));

export default function Switches({
  chartType,
  setChartType,
  setRefreshRdm,
  darkColor,
}) {
  const classes = useStyles({darkColor});
  const [checked, setChecked] = React.useState(
    chartType === "radar" ? false : true
  );

  const handleChange = (event) => {
    setChartType(checked ? "radar" : "bar");
    setRefreshRdm(Math.random());
    setChecked((prv) => !checked);
  };

  return (
    <div className={classes.switchChartContainer}>
      <img
        className={classes.radarIcon}
        alt="radar chart icon"
        src={
          process.env.PUBLIC_URL +
          "/img/radar_chart" +
          (darkColor ? "_dark" : "") +
          ".svg"
        }
        onClick={handleChange}
      />

      <Switch
        checked={checked}
        onChange={handleChange}
        color="primary"
        inputProps={{ "aria-label": "primary checkbox" }}
      />
      <BarChartIcon
        onClick={handleChange}
        style={{ color: darkColor ? "#0e7681" : "white" }}
      />
    </div>
  );
}
