import React, { useContext, useEffect } from "react";
import { UserContext, SetGroupesContext } from "../lib/context";
import firebase from "../lib/firebase";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/core/styles";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import IconButton from "@material-ui/core/IconButton";
import Chip from "@material-ui/core/Chip";
import TagFacesIcon from "@material-ui/icons/TagFaces";
import TextField from "@material-ui/core/TextField";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Alert from "@material-ui/lab/Alert";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiPaper-root": {
      minWidth: "40%",
    },
  },
  chip: {
    margin: theme.spacing(0.5),
    background: "#1F4695",
    color: "white",
    "& .MuiSvgIcon-root": {
      color: "white",
    },
  },
  addParticipantContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    maxWidth: "100%",
  },
  chips: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 10,
    flexWrap: "wrap",
    maxHeight: 150,
    overflowY: "auto",
  },
  chipsInput: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    marginBottom: 5,
  },
  addChipBtn: {
    marginTop: 20,
    background: "linear-gradient( 136deg,#0E7681   0%,#0E7681 100%)",
    color: "white",
    "& .MuiSvgIcon-root": {
      marginLeft: 10,
      color: "white",
    },
  },
  errorMsg: {
    color: "red",
    textAlign: "center",
  },
  addBtn: {
    color: "#707070",
    padding: 8,
  },
}));

export default function AjouterParticipantDialog({ groupeData }) {
  const classes = useStyles();
  const db = firebase.firestore();
  const user = useContext(UserContext);
  const setGroupes = useContext(SetGroupesContext);
  const [open, setOpen] = React.useState(false);
  const [participantValue, setParticipantValue] = React.useState("");
  const [chipData, setChipData] = React.useState([]);
  const [errorMsg, setErrorMsg] = React.useState("");

  useEffect(() => {
    setChipData([]);
  }, [open]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (e,reason) => {
    if (!reason || reason !== "backdropClick") {
      setOpen(false);
    }
  };

  const handleChangeParticipant = (e) => {
    setParticipantValue(e.target.value);
  };
  const handleAddParticipants = () => {
    let newParticipantsArray = [...groupeData.data.participants];
    let tempArray = [];
    chipData.forEach((chip) => {
      let participantExists = false;
      newParticipantsArray.forEach((participant) => {
        if (participant.toLowerCase() === chip.label.toLowerCase()) {
          participantExists = true;
        }
      });
      if (!participantExists) {
        tempArray.push(chip.label);
      }
    });
    newParticipantsArray = newParticipantsArray.concat(tempArray);
    if (user.email) {
      let groupeRef = db.collection("Groupes").doc(groupeData.id);
      groupeRef
        .update({
          participants: newParticipantsArray,
        })
        .then(function (docRef) {
          console.log("Document written");
          groupeData.data.participants = newParticipantsArray;

          setGroupes((prv) => {
            let groupeDataIndex = prv.findIndex(
              (_groupeData) => _groupeData.id === groupeData.id
            );
            if (groupeDataIndex === -1) {
              return prv;
            }
            prv.splice(groupeDataIndex, 1, groupeData);
            return [...prv];
          });
          handleClose();
          // refreshGroupes();
        })
        .catch(function (error) {
          let frenchErrorMsg = null;
          if (error.code === "permission-denied") {
            frenchErrorMsg =
              "Vous n'avez pas les droits pour faire cette action";
          }
          setErrorMsg(frenchErrorMsg ? frenchErrorMsg : error.code);
          console.error("Error adding document: ", error);
          // handleClose();
        });
    } else {
      setErrorMsg("Connection perdue, veuillez vous reconnecter.");
      handleClose();
    }
  };
  const handleDeleteChip = (chipToDelete) => () => {
    setChipData((chips) =>
      chips.filter((chip) => chip.key !== chipToDelete.key)
    );
  };
  const addChip = () => {
    let errorMsg = "";

    chipData.forEach((data) => {
      if (data.label.toLowerCase() === participantValue.toLowerCase()) {
        errorMsg = "Ce participant existe déja.";
      }
    });
    if (participantValue.length < 3) {
      errorMsg = "Trois lettres minimum.";
    }
    if (!errorMsg) {
      setParticipantValue("");
      setErrorMsg("");
      setChipData((chips) => [
        ...chips,
        { key: chips.length, label: participantValue },
      ]);
    } else {
      setErrorMsg(errorMsg);
    }
  };
  return (
    <div>
      <Tooltip
        arrow
        TransitionComponent={Zoom}
        title="Ajouter un participant"
      >
        <IconButton className={classes.addBtn + " add_participant_btn"} onClick={handleClickOpen}>
          <PersonAddIcon  />
        </IconButton>
      </Tooltip>
      <Dialog
        className={classes.root}
        open={open}
        onClose={handleClose}

        disableEscapeKeyDown
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Ajouter des participants."}
        </DialogTitle>
        <DialogContent>
          <div className={classes.addParticipantContainer}>
            <div className={classes.chipsInput}>
              <TextField
                id="standard-basic"
                label="Participant"
                onChange={handleChangeParticipant}
                value={participantValue}
                autoComplete="off"
                onKeyPress={(ev) => {
                  if (ev.key === "Enter") {
                    addChip();
                  }
                }}
              />
              <div>
                <Button
                  onClick={addChip}
                  className={classes.addChipBtn}
                  variant="contained"
                >
                  Ajouter
                  <AddCircleIcon color="primary" />
                </Button>
              </div>
            </div>
            <div className={classes.chips}>
              {chipData.map((data) => {
                let icon;

                if (data.label === "React") {
                  icon = <TagFacesIcon />;
                }

                return (
                  <div key={data.key + data.label}>
                    <Chip
                      icon={icon}
                      label={data.label}
                      onDelete={
                        data.label === "React"
                          ? undefined
                          : handleDeleteChip(data)
                      }
                      className={classes.chip}
                    />
                  </div>
                );
              })}
            </div>
          </div>
          {errorMsg.length > 0 ? (
            <Alert severity="error">{errorMsg}</Alert>
          ) : (
            ""
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Annuler
          </Button>
          <Button onClick={handleAddParticipants} color="primary" autoFocus>
            Terminer
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
