import React, { useEffect, useState } from "react";
import "./App.css";
import firebase from "./lib/firebase";
import { UserContext, UserRoleContext } from "./lib/context";
import { roles } from "./lib/roles";
import AuthForms from "./components/authforms";
import MainApp from "./components/mainapp";
import CircularProgress from "@material-ui/core/CircularProgress";

function App() {
  const auth = firebase.auth();
  const db = firebase.firestore();
  const [user, setUser] = useState(null);
  const [userRole, setRole] = useState(roles.GUEST);
  const [loadingUser, setLoadingUser] = useState(true);
  useEffect(() => {
    window.scrollTo(0, 1);
    let isSubscribed = true;

    const unsubscribe = auth.onAuthStateChanged(async (newUser) => {
      if (newUser) {
        if (isSubscribed) {
          try {
            let _userRole = roles.GUEST;
            let userDocRef = await db
              .collection("Utilisateurs")
              .where("email", "==", newUser.email)
              .get();
            if (userDocRef.empty) {
              await db
                .collection("Utilisateurs")
                .doc(newUser.uid)
                .set({
                  email: newUser.email,
                  name: newUser.email.split("@")[0],
                  role: _userRole,
                });
              await newUser.updateProfile({
                displayName: newUser.email.split("@")[0],
              });
              fetch(
                "https://recrutgame.synergiefamily.com/api/alertnewco",
                {
                  method: "POST",
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({ senderMail: newUser.email }),
                }
              );
            } else {
              userDocRef.forEach((doc) => {
                if (doc.data() && typeof doc.data().role === "number") {
                  _userRole = doc.data().role;
                }
              });
            }
            setRole(_userRole);
            setUser(newUser);
            setLoadingUser(false);
          } catch (error) {
            console.log(error);
            setUser(null);
            setLoadingUser(false);
          }
        }
      } else {
        setUser(null);
        setLoadingUser(false);
      }
    });
    return () => {
      isSubscribed = false;
      unsubscribe();
    };
  }, [auth, db]);
  return (
    <div className="App">
      {loadingUser ? (
        <div className="loading-screen">
          <CircularProgress />
        </div>
      ) : user ? (
        <UserContext.Provider value={user}>
          <UserRoleContext.Provider value={userRole}>
            <MainApp setRole={setRole} />
          </UserRoleContext.Provider>
        </UserContext.Provider>
      ) : (
        <AuthForms />
      )}
    </div>
  );
}

export default App;
