import React, { useState } from "react";
import firebase from "../lib/firebase";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

export default function ForgotPasswordDialog({ setForgotPasswordOpen, showAlertMessage }) {

  const [email, setEmail] = useState("");
  const auth = firebase.auth();
  const handleClose = () => {
    setForgotPasswordOpen(false);
  };
  const handleSendEmail = async () => {
    try {
      if (email.length > 0) {
        await auth.sendPasswordResetEmail(email);
        showAlertMessage(
          "Vous allez recevoir un email pour réinitialiser votre mot de passe à l'adresse: " +
            email,
          "success"
        );
        handleClose();
      }
    } catch (error) {
      console.log(error);
      let frenchErrorMsg = "";
      if (error.code === "auth/invalid-email"){
        frenchErrorMsg = "Adresse mail invalide"
      }
      showAlertMessage(
        frenchErrorMsg ? frenchErrorMsg :error.message,
        "error"
      );
      handleClose();
    }
  };
  const handleInputChange = (e) => {
    setEmail(e.target.value);
  };
  return (
    <div>
      <Dialog
        open={true}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Mot de passe oublié</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Pour réinitialiser votre mot de passe, veuillez renseigner une adresse mail.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Adresse mail"
            type="email"
            fullWidth
            onChange={handleInputChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Annuler
          </Button>
          <Button onClick={handleSendEmail} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
