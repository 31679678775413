import React, { useContext, useEffect } from "react";
import { UserContext } from "../lib/context";
import firebase from "../lib/firebase";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { makeStyles } from "@material-ui/core/styles";
// import CategoryIcon from "@material-ui/icons/Category";

import EvaluationTable from "./evaluationtable";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import Alert from "@material-ui/lab/Alert";
import anime from "animejs";

const useStyles = makeStyles((theme) => ({
  tabs: {
    marginTop: 16,
    alignSelf:"center",
    [theme.breakpoints.down("sm")]: {
      marginTop: 10,
    },
    // height: "100%",
    // maxHeight: 70,
    fontSize: 12,
    background: "transparent",
    "& .MuiTabs-scroller": {
      background: "transparent",
    },
    "& .MuiPaper-root": {
      background: "transparent",
    },
    "& .MuiTabs-flexContainer": {
      justifyContent: "center",
      background: "transparent",
    },
    "& .Mui-selected": {
      color: "#0E7681",
    },
    "& .MuiTabs-indicator": {
      background: "#0E7681",
    },
    "& .MuiTab-wrapper": {
      [theme.breakpoints.down("xs")]: {
        fontSize: 12,
      },
    },
  },
  tabContent: {
    display:"flex",
    justifyContent:"space-between",
    alignItems:"flex-start",
    flexDirection:"column",
    // height: "calc(100vh - 200px)",
    width: "100vw",
    minHeight:"100%",
    boxSizing: "border-box",
  },
  tablesContainer: (props) => ({
    // height: "calc(100vh - 260px)",
    overflowY: "hidden",
    overflowX: "hidden",
    // position: "absolute",
    width: props.tablesContainerWidth,
    left: 0,
    display: "flex",
    flexWrap: "no-wrap",
    paddingTop: 20,
    // [theme.breakpoints.down("sm")]: {
    //   paddingTop: 0,
    // },
    paddingBottom: 50,
    boxSizing: "border-box",
  }),
  loading: {
    width: "100vw",
    height: "calc(100vh - 230px)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  bilanBtnContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    marginTop:"auto",
    // marginBottom: 60,
  },
  bilanBtn: {
    // position: "fixed",

    width: 200,
    // bottom: 100,
    // left: "calc(50vw - 100px)",
    alignSelf: "center",
    background: "#0E7681",
    color: "white",
    zIndex: 1000,
    "&:hover": {
      background: "#0E7681",
    },
  },
}));
export default function Evaluation({
  groupeId,
  showBilan,
  refreshRdm,
  filterName,
}) {
  const db = firebase.firestore();
  const user = useContext(UserContext);

  const [value, setValue] = React.useState(0);
  const [loadingEval, setLoadingEval] = React.useState(false);
  const [evalData, setEvalData] = React.useState(null);
  const [groupeData, setGroupeData] = React.useState(null);
  const [errorMsg, setErrorMsg] = React.useState(null);
  const tablesContainerRef = React.createRef();
  const classes = useStyles({
    tablesContainerWidth:
      groupeData && groupeData.jeux ? 100 * groupeData.jeux.length + "vw" : 0,
  });
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    anime({
      targets: tablesContainerRef.current,
      translateX: value === 0 ? "0vw" : value * -100 + "vw",
      easing: "easeOutElastic(0.3, 1.5)",
      // translateY:"calc(30vh - 50%)",
      duration: 1000,
    });
  }, [value, tablesContainerRef]);

  useEffect(() => {
    let isMounted = true;
    async function fetchGroupeDataAndEval(groupeId) {
      if (user.email) {
        setLoadingEval(true);
        try {
          let fetchedGroupeData = await db
            .collection("Groupes")
            .doc(groupeId)
            .get();
          fetchedGroupeData = {
            id: fetchedGroupeData.id,
            ...fetchedGroupeData.data(),
          };
          if (fetchedGroupeData && isMounted) {
            fetchedGroupeData.jeux.forEach((jeu) => {
              jeu.softSkills = jeu.softSkills.sort((a, b) => (a < b ? -1 : 1));
            });
            let fetchedEvalSnapshot = await db
              .collection("Evaluations")
              .where("groupe", "==", groupeId)
              .where("evaluateurUid", "==", user.uid)
              .get();
            if (isMounted) {
              let evalData;
              fetchedEvalSnapshot.forEach(function (doc) {
                evalData = { ...doc.data(), id: doc.id };
              });
              if (evalData) {
                if (fetchedGroupeData.participants.length === 0) {
                  setErrorMsg("Ce groupe ne contient aucun participant");
                  setLoadingEval(false);
                } else {
                  let newParticipantNames = []; // on va rajouter les évaluations pour des participant qui auraient été rajouté entre temps.
                  let noMoreExistingParticipantNames = []; // ou supprimé entre temps.
                  fetchedGroupeData.participants.forEach((pName) => {
                    if (!evalData.participants.some((p) => p.name === pName)) {
                      newParticipantNames.push(pName);
                    }
                  });
                  evalData.participants.forEach((p) => {
                    if (!fetchedGroupeData.participants.includes(p.name)) {
                      noMoreExistingParticipantNames.push(p.name);
                    }
                  });
                  let evalDataIsUpdated = false;
                  if (noMoreExistingParticipantNames.length > 0) {
                    evalData.participants = evalData.participants.filter(
                      (p) =>
                        !noMoreExistingParticipantNames.some(
                          (pName) => p.name === pName
                        )
                    );
                    evalDataIsUpdated = true;
                  }
                  if (newParticipantNames.length > 0) {
                    newParticipantNames.forEach((p) => {
                      evalData.participants.push({ name: p });
                    });
                    evalDataIsUpdated = true;
                  }
                  if (evalDataIsUpdated) {
                    let updatedParticipants = [...evalData.participants];
                    await db
                      .collection("Evaluations")
                      .doc(evalData.id)
                      .set(
                        {
                          participants: [...updatedParticipants],
                        },
                        { merge: true }
                      );
                    console.log("Eval updated");
                  }
                  if (isMounted) {
                    setEvalData(evalData);
                    setLoadingEval(false);
                  }
                }
              } else {
                let dataToAdd = {
                  evaluateurMail: user.email,
                  evaluateurUid: user.uid,
                  evaluateurName: user.displayName,
                  groupe: groupeId,
                  participants: fetchedGroupeData.participants.map(
                    (participantName) => {
                      let emptyJeuEval = {};
                      fetchedGroupeData.jeux.forEach((jeu) => {
                        emptyJeuEval[jeu.name] = {};
                      });
                      return {
                        name: participantName,
                        ...emptyJeuEval,
                      };
                    }
                  ),
                };
                let docRef = await db.collection("Evaluations").add(dataToAdd);
                if (isMounted) {
                  setEvalData({ ...dataToAdd, id: docRef.id });
                  setLoadingEval(false);
                }
              }
            }
            if (isMounted) {
              setGroupeData(fetchedGroupeData);
            }
          }
          if (isMounted) {
            setLoadingEval(false);
          }
        } catch (e) {
          if (isMounted) {
            console.log(e);
            setErrorMsg("Une erreur est survenue");
            setLoadingEval(false);
          }
        }
      } else {
        setLoadingEval(false);
      }
    }
    fetchGroupeDataAndEval(groupeId);
    return () => {
      isMounted = false;
    };
  }, [refreshRdm, db, groupeId, user]);

  return groupeData && evalData && !loadingEval ? (
    <div className={classes.tabContent}>
      <Tabs
        className={classes.tabs}
        value={value}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChange}
        aria-label="tabs"
      >
        {groupeData.jeux.map((jeu) => (
          <Tab
            key={jeu.name}
            label={jeu.name}
            // icon={<CategoryIcon />}
          />
        ))}
      </Tabs>

      <div ref={tablesContainerRef} className={classes.tablesContainer}>
        {groupeData.jeux.map((jeu) => (
          <EvaluationTable
            key={jeu.name}
            groupeData={groupeData}
            jeu={jeu}
            evalData={evalData}
            evalId={evalData.id}
            filterName={filterName}
          />
        ))}
      </div>
      <div className={classes.bilanBtnContainer}>
        <Button
          onClick={() => {
            showBilan({ id: groupeData.id, data: groupeData }, true);
          }}
          className={classes.bilanBtn}
          variant="contained"
        >
          BILAN
        </Button>
      </div>
    </div>
  ) : errorMsg ? (
    <Alert severity="error">{errorMsg}</Alert>
  ) : (
    <div className={classes.loading}>
      <CircularProgress />
    </div>
  );
}
