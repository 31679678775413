import React, { useState, useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import firebase from "../lib/firebase";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import ChangeGameNameDialog from "./changegamenamedialog";
import ChoseSoftSkillsDialog from "./chosesoftskillsdialog";
import { UserRoleContext } from "../lib/context";
import { roles } from "../lib/roles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const useStyles = makeStyles({
  root: {
    maxWidth: 400,
    margin: 10,
    padding: 12,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "column",
    boxShadow:
      "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
    border: "none",
    borderRadius: "16px",
  },

  gameName: { display: "flex", justifyContent: "center", alignItems: "center" },
  chip: {
    margin: 6,
  },
  flexCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  flexBetween: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  chosePremadeGameSelect: {
    minWidth: 150,
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#3f51b5!important",
    },
  },
  topBtnsContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "stretch",
  },
  saveThisGameBtn: {
    minWidth: "150px",
  },
  progressInSelect: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  snackBar: {
    marginBottom: 70,
    zIndex: 100000,
  },
});

export default function EditableGameCard({
  index,
  game,
  games,
  setGames,
  fromManageGames,
  savePremadeGames,
}) {
  const classes = useStyles();
  const db = firebase.firestore();
  const userRole = useContext(UserRoleContext);
  const [selectedGame, setSelectedGame] = useState("");
  const [chipData] = useState(
    game.softSkills.map((softSkill) => ({ key: softSkill, label: softSkill }))
  );
  const [loadingPremadeGames, setLoadingPremadeGames] = useState(true);
  const [loadingSavePremadeGame, setLoadingSavePremadeGame] = useState(false);
  const [premadeGames, setPremadeGames] = useState([]);
  const [openSnack, setOpenSnack] = React.useState(false);
  const handleDeleteChip = (chipToDelete) => () => {
    games.forEach((_game) => {
      if (_game.name === game.name) {
        game.softSkills = chipData
          .filter((chip) => chip.key !== chipToDelete.key)
          .map((chip) => chip.key);
      }
    });
    if (fromManageGames) {
      savePremadeGames(games);
    }
    setGames([...games]);
  };
  const handleDeleteGame = () => {
    let filteredGames = [...games.filter((_game) => _game.name !== game.name)];
    if (fromManageGames) {
      savePremadeGames(filteredGames);
    }
    setGames(filteredGames);
  };
  const handleChangeSelectGame = (event) => {
    setSelectedGame(event.target.value);
  };
  const getJeuVideName = (existingGames) => {
    let gameNb = 1;
    let newName = "Jeu " + gameNb;
    while (existingGames.find((_game) => _game.name === newName)) {
      gameNb++;
      newName = "Jeu " + gameNb;
    }
    return newName;
  };
  useEffect(() => {
    if (
      typeof selectedGame === "string" &&
      selectedGame.length > 0 &&
      !games.find((_game) => _game.name === selectedGame)
    ) {
      let thisGameIndex = games.findIndex((_game) => _game.name === game.name);
      if (selectedGame === "@jeu_vide@") {
        let modifiedGames = [...games];
        modifiedGames[thisGameIndex] = {
          name: getJeuVideName(games),
          softSkills: [],
        };
        setGames(modifiedGames);
      } else {
        let chosenPremadeGame = premadeGames.find(
          (_game) => _game.name === selectedGame
        );
        if (chosenPremadeGame) {
          let modifiedGames = [...games];
          modifiedGames[thisGameIndex] = chosenPremadeGame;
          setGames(modifiedGames);
        }
      }
    }
  }, [selectedGame]);
  const saveThisGameToPremadeGames = async () => {
    try {
      setLoadingSavePremadeGame(true);
      let savedGames = await db.collection("Games").doc("games-list").get();

      savedGames =
        savedGames.data() && savedGames.data().games
          ? savedGames.data().games
          : [];
      if (Array.isArray(savedGames)) {
        let alreadyExistingInPremadeGameIndex = savedGames.findIndex(
          (_game) => _game.name === game.name
        );
        if (
          typeof alreadyExistingInPremadeGameIndex === "number" &&
          alreadyExistingInPremadeGameIndex >= 0
        ) {
          savedGames[alreadyExistingInPremadeGameIndex] = game;
        } else {
          savedGames.push(game);
        }
        await db
          .collection("Games")
          .doc("games-list")
          .set({ games: savedGames });
        setOpenSnack(true);
      }
      setLoadingSavePremadeGame(false);
    } catch (error) {
      console.log(error);
      setLoadingSavePremadeGame(false);
    }
  };
  const selectGamesFocused = async () => {
    try {
      setLoadingPremadeGames(true);
      let savedGames = await db.collection("Games").doc("games-list").get();

      savedGames =
        savedGames.data() && savedGames.data().games
          ? savedGames.data().games
          : [];
      if (Array.isArray(savedGames)) {
        setPremadeGames(
          savedGames.filter(
            (premadeGame) =>
              premadeGame.name !== game.name &&
              !games.find((_game) => _game.name === premadeGame.name)
          )
        );
      }
      setLoadingPremadeGames(false);
    } catch (error) {
      console.log(error);
      setLoadingPremadeGames(false);
    }
  };
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
  };
  return (
    <Card className={classes.root} variant="outlined">
      <Snackbar
        open={openSnack}
        autoHideDuration={3000}
        onClose={handleCloseSnack}
        className={classes.snackBar}
      >
        <Alert onClose={handleCloseSnack} severity="success">
          Jeu sauvegardé dans les jeux pré-enregistré
        </Alert>
      </Snackbar>
      {!fromManageGames && (
        <CardActions className={classes.topBtnsContainer}>
          <FormControl
            variant="outlined"
            className={classes.chosePremadeGameSelect}
          >
            <InputLabel id="demo-simple-select-outlined-label">
              Choisir un jeu
            </InputLabel>
            <Select
              labelId="select_game_label"
              id="select_game"
              value={selectedGame}
              onChange={handleChangeSelectGame}
              label="Choisir un jeu"
              onOpen={selectGamesFocused}
            >
              {loadingPremadeGames && !premadeGames ? (
                <div className={classes.progressInSelect}>
                  <CircularProgress />
                </div>
              ) : (
                [{ name: "@jeu_vide@" }]
                  .concat(premadeGames)
                  .map((premadeGame) => (
                    <MenuItem key={premadeGame.name} value={premadeGame.name}>
                      {premadeGame.name === "@jeu_vide@"
                        ? "Jeu vide"
                        : premadeGame.name}
                    </MenuItem>
                  ))
              )}
            </Select>
          </FormControl>
          {userRole <= roles.ADMIN && (
            <Button
              onClick={saveThisGameToPremadeGames}
              variant="outlined"
              size="small"
              color="primary"
              className={classes.saveThisGameBtn}
            >
              {loadingSavePremadeGame ? (
                <CircularProgress />
              ) : (
                "Enregistrer ce jeu"
              )}
            </Button>
          )}
        </CardActions>
      )}
      <CardContent>
        <Typography
          className={classes.gameName}
          gutterBottom
          variant="h5"
          component="h2"
        >
          <div>{game.name}</div>{" "}
          <ChangeGameNameDialog
            setGames={setGames}
            games={games}
            game={game}
            savePremadeGames={savePremadeGames}
            fromManageGames={fromManageGames}
          />
        </Typography>
        {chipData.map((data) => {
          return (
            <Chip
              key={data.label + Math.random()}
              color="primary"
              variant="outlined"
              label={data.label}
              onDelete={handleDeleteChip(data)}
              className={classes.chip}
            />
          );
        })}
      </CardContent>

      <CardActions
        className={
          index !== 0 || fromManageGames
            ? classes.flexBetween
            : classes.flexCenter
        }
      >
        {(index !== 0 || fromManageGames) && (
          <Button
            color="secondary"
            variant="outlined"
            onClick={handleDeleteGame}
            size="small"
          >
            Supprimer
          </Button>
        )}
        <ChoseSoftSkillsDialog
          game={game}
          games={games}
          setGames={setGames}
          savePremadeGames={savePremadeGames}
          fromManageGames={fromManageGames}
        />
      </CardActions>
    </Card>
  );
}
