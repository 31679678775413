import React, { useContext, useState } from "react";
import { UserContext } from "../lib/context";
import firebase from "../lib/firebase";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import GameAndSoftSkillSelector from "./gameandsoftskillselector";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import TextField from "@material-ui/core/TextField";
import EditIcon from "@material-ui/icons/Edit";
import CategoryIcon from "@material-ui/icons/Category";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import StepConnector from "@material-ui/core/StepConnector";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";
import Chip from "@material-ui/core/Chip";
import TagFacesIcon from "@material-ui/icons/TagFaces";
import AddIcon from "@material-ui/icons/Add";
import Alert from "@material-ui/lab/Alert";
import CircularProgress from "@material-ui/core/CircularProgress";
import Fab from "@material-ui/core/Fab";

import "react-datepicker/dist/react-datepicker.css";
import GroupeCard from "./groupcard";

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    "& $line": {
      backgroundImage: "linear-gradient( 136deg,#009BE5   0%,#009BD8 100%)",
    },
  },
  completed: {
    "& $line": {
      backgroundImage: "linear-gradient( 136deg,#009BE5   0%,#009BD8 100%)",
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    backgroundImage: "linear-gradient( 136deg,#009BE5   0%,#009BD8 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  },
  completed: {
    backgroundImage: "linear-gradient( 136deg,#009BE5   0%,#009BD8 100%)",
  },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <EditIcon />,
    2: <EventAvailableIcon />,
    3: <GroupAddIcon />,
    4: <CategoryIcon />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.node,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "40px 0",
    "& .MuiStepper-horizontal": {
      width: "100%",
      maxWidth: 800,
      margin: 0,
      padding: 0,
    },
    "& .MuiStepper-root": {
      background: "white",
    },
    "& .react-datepicker-wrapper": {
      maxHeight: 60,
    },
    "& .react-datepicker__input-container input": {
      background: "linear-gradient( 136deg,#009BE5   0%,#009BD8 100%)",
      border: "none",
      padding: 20,
      borderRadius: 5,
      color: "white",
      cursor: "pointer",
      fontSize: 18,
      textAlign: "center",
      transition: "all 0.3s ease-in-out",
      "&:hover": {
        transform: "scale(1.1)",
      },
    },
  },

  button: {
    marginRight: theme.spacing(1),
    background: "#009BE5",
    color: "white",
    margin: 10,
    width: 125,
    "&:focus": {
      background: "#009BE5",
      color: "white",
    },
  },
  instructionsContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    width: "100%",
  },
  instructions: {
    width: "100%",
    minHeight: 100,
    marginTop: 22,
    marginBottom: theme.spacing(1),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: 10,
  },
  buttons: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    display: "flex",
    justifyContent: "center",
  },
  errorMsg: {
    color: "red",
    textAlign: "center",
  },
  chip: {
    margin: theme.spacing(0.5),
    background: "#1F4695",
    color: "white",
    "& .MuiSvgIcon-root": {
      color: "white",
    },
  },
  addParticipantContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    maxWidth: "100%",
  },
  chips: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 10,
    flexWrap: "wrap",
    maxHeight: 150,
    overflowY: "auto",
  },
  chipsInput: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // flexDirection: "column",
    marginBottom: 5,
  },
  addChipBtn: {
    // marginTop: 10,
    // background: "#3F51B5",
    // color: "white",
    marginLeft: 20,
    background: "#009BE5",
    "& .MuiSvgIcon-root": {
      // marginLeft: 10,
      color: "white",
    },
    "&:hover": {
      background: "#3F51B5",
      transform: "scale(1.05)",
    },
  },
  creationWait: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  stepper: {
    background: "white!important",
  },
}));

function getSteps() {
  return ["Titre", "Date", "Participants", "Jeux"];
}

export default function AjouterGroupe({ setBottomNavValue, groupToModify }) {
  const classes = useStyles();
  const db = firebase.firestore();
  const user = useContext(UserContext);
  const [activeStep, setActiveStep] = React.useState(0);
  const [startDate, setStartDate] = useState(
    groupToModify
      ? new Date(groupToModify.data.date.seconds * 1000)
      : new Date()
  );
  const [errorMsg, setErrorMsg] = useState("");
  const [titre, setTitre] = useState(
    groupToModify ? groupToModify.data.titre : ""
  );
  const [participantValue, setParticipantValue] = useState("");
  const [participantChipData, setParticipantChipData] = React.useState(
    groupToModify
      ? groupToModify.data.participants.map((p, index) => {
          return {
            key: index,
            label: p,
          };
        })
      : []
  );
  const [games, setGames] = React.useState(
    groupToModify
      ? groupToModify.data.jeux
      : [{ name: "Jeu 1", softSkills: [] }]
  );

  const handleDeleteChip = (chipToDelete) => () => {
    setParticipantChipData((chips) =>
      chips.filter((chip) => chip.key !== chipToDelete.key)
    );
  };

  registerLocale("fr", fr);

  const steps = getSteps();

  const handleChangeTitre = (e) => {
    setTitre(e.target.value);
  };
  const handleChangeParticipant = (e) => {
    setParticipantValue(e.target.value);
  };
  const addChip = () => {
    let errorMsg = "";

    participantChipData.forEach((data) => {
      if (data.label.toLowerCase() === participantValue.toLowerCase()) {
        errorMsg = "Ce participant existe déja.";
      }
    });
    if (participantValue.length < 3) {
      errorMsg = "Trois lettres minimum.";
    }
    if (!errorMsg) {
      setParticipantValue("");
      setErrorMsg("");
      setParticipantChipData((chips) => [
        ...chips,
        { key: chips.length, label: participantValue },
      ]);
    } else {
      setErrorMsg(errorMsg);
    }
  };
  const updateGroupe = async () => {
    try {
      let fetchedEvalSnapshot = await db
        .collection("Evaluations")
        .where("groupe", "==", groupToModify.id)
        .get();
      let tempEvalsData = [];
      fetchedEvalSnapshot.forEach(function (doc) {
        tempEvalsData.push({ ...doc.data(), id: doc.id });
      });
      if (tempEvalsData.length === 0) {
        await db
          .collection("Groupes")
          .doc(groupToModify.id)
          .update({
            date: startDate,
            participants: participantChipData.map((chip) => chip.label),
            titre: titre,
            jeux: games,
          });
        console.log("Document written");
        setBottomNavValue(1);
      } else {
        setErrorMsg(
          "Cette session contient des évaluations, elle ne peut être modifiée"
        );
      }
    } catch (error) {
      let frenchErrorMsg = null;
      if (error.code === "permission-denied") {
        frenchErrorMsg = "Vous n'avez pas les droits pour faire cette action";
      }
      setErrorMsg(frenchErrorMsg ? frenchErrorMsg : error.code);
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
      console.error("Error adding document: ", error);
    }
  };
  const createGroupe = () => {
    if (user.email) {
      db.collection("Groupes")
        .add({
          creator: user.displayName
            ? user.displayName
            : user.email.split("@")[0].toLowerCase(),
          date: startDate,
          participants: participantChipData.map((chip) => chip.label),
          titre: titre,
          evaluateurs: [],
          jeux: games,
        })
        .then(function (docRef) {
          console.log("Document written");
          setBottomNavValue(1);
        })
        .catch(function (error) {
          let frenchErrorMsg = null;
          if (error.code === "permission-denied") {
            frenchErrorMsg =
              "Vous n'avez pas les droits pour faire cette action";
          }
          setErrorMsg(frenchErrorMsg ? frenchErrorMsg : error.code);
          setActiveStep((prevActiveStep) => prevActiveStep - 1);
          console.error("Error adding document: ", error);
        });
    } else {
      setErrorMsg("Veuillez vous reconnecter.");
    }
  };
  const isValidGroupe = () => {
    let error = null;
    games.forEach((game) => {
      if (
        !game.softSkills ||
        !Array.isArray(game.softSkills) ||
        game.softSkills.length === 0
      ) {
        error = "Veuillez ajouter au moins un soft skill par jeu";
      }
    });
    return error;
  };
  const handleNext = () => {
    let errorMsg = "";
    if (activeStep === 0) {
      if (titre.length === 0) {
        errorMsg = "Veuillez renseigner un titre.";
      }
    }
    if (activeStep === 3) {
      errorMsg = isValidGroupe();
      if (!errorMsg) {
        if (groupToModify) {
          updateGroupe();
        } else {
          createGroupe();
        }
      } else {
        setErrorMsg(errorMsg);
      }
    } else {
      if (!errorMsg) {
        setErrorMsg("");
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } else {
        setErrorMsg(errorMsg);
      }
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <div className={classes.root}>
      <Stepper
        className={classes.stepper}
        alternativeLabel
        activeStep={activeStep}
        connector={<ColorlibConnector />}
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      {activeStep === steps.length ? (
        <div className={classes.creationWait}>
          <Typography className={classes.instructions}>
            Création du groupe, veuillez patienter.
          </Typography>
          <CircularProgress />
          {/* <Button onClick={handleReset} className={classes.button}>
              Reset
            </Button> */}
        </div>
      ) : (
        <div className={classes.instructionsContainer}>
          <div className={classes.instructions}>
            {activeStep === 0 ? (
              <TextField
                onChange={handleChangeTitre}
                id="outlined-basic"
                value={titre}
                label="Titre"
                variant="outlined"
                autoComplete="off"
                onKeyPress={(ev) => {
                  if (ev.key === "Enter") {
                    ev.target.blur();
                  }
                }}
              />
            ) : activeStep === 1 ? (
              <DatePicker
                locale="fr"
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                timeIntervals={15}
                timeCaption="time"
                todayButton="Aujourd'hui"
                dateFormat="MMMM d, yyyy"
                onFocus={(e) => e.target.blur()}
              />
            ) : activeStep === 2 ? (
              <div className={classes.addParticipantContainer}>
                <div className={classes.chipsInput}>
                  <TextField
                    id="standard-basic"
                    label="Participant"
                    onChange={handleChangeParticipant}
                    value={participantValue}
                    autoComplete="off"
                    onKeyPress={(ev) => {
                      if (ev.key === "Enter") {
                        addChip();
                      }
                    }}
                  />
                  <div>
                    <Fab
                      size="small"
                      onClick={addChip}
                      className={classes.addChipBtn}
                      color="primary"
                      aria-label="add"
                    >
                      <AddIcon />
                    </Fab>
                  </div>
                </div>
                <div className={classes.chips}>
                  {participantChipData.map((data) => {
                    let icon;

                    if (data.label === "React") {
                      icon = <TagFacesIcon />;
                    }

                    return (
                      <div key={data.key + data.label}>
                        <Chip
                          icon={icon}
                          label={data.label}
                          onDelete={
                            data.label === "React"
                              ? undefined
                              : handleDeleteChip(data)
                          }
                          className={classes.chip}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : activeStep === 3 ? (
              <GameAndSoftSkillSelector games={games} setGames={setGames} />
            ) : (
              ""
            )}
          </div>
          {errorMsg.length > 0 ? (
            <Alert severity="error">{errorMsg}</Alert>
          ) : (
            ""
          )}

          <div className={classes.buttons}>
            <Button
              color="primary"
              variant="contained"
              disabled={activeStep === 0}
              onClick={handleBack}
              className={classes.button}
            >
              Précedent
            </Button>
            <Button
              variant="contained"
              // disabled={activeStep === steps.length - 1 }
              color="primary"
              onClick={handleNext}
              className={classes.button}
            >
              {activeStep === steps.length - 1 ? "Terminer" : "Suivant"}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}
