import React, { useEffect, useState, useContext } from "react";
import { SetGroupesContext } from "../lib/context";
import firebase from "../lib/firebase";
import { roles } from "../lib/roles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Switch from "@material-ui/core/Switch";
import CircularProgress from "@material-ui/core/CircularProgress";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";

const useStyles = makeStyles((theme) => ({
  rootList: {
    width: "100%",
    maxWidth: 550,
    minWidth: 300,
    maxHeight: 450,
    backgroundColor: theme.palette.background.paper,
  },
  dialog: {
    zIndex: "100000!important",
    "& .MuiDialog-paper": {
      maxHeight: "96%",
    },
  },
  manageBtn: {
    color: "#707070",
    margin: 0,
    padding: 8,
  },
  flexedLoading: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: 15,
  },
}));

export default function GererEvaluateursDialog({ groupeData }) {
  const classes = useStyles("");
  const db = firebase.firestore();
  const setGroupes = useContext(SetGroupesContext);
  const [isLoading, setIsLoading] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const [checked, setChecked] = React.useState(groupeData.data.evaluateurs);
  const [potentielEvaluateurs, setPotentielEvaluateurs] = useState([]);

  useEffect(() => {
    let isMounted = true;
    if (open) {
      (async () => {
        try {
          const usersSnapshot = await db
            .collection("Utilisateurs")
            .orderBy("name", "asc")
            .get();
          if (isMounted) {
            let users = [];
            usersSnapshot.forEach(function (doc) {
              const user = doc.data();
              if (user.role !== 4) {
                users.push({ ...user, uid: doc.id });
              }
            });

            setPotentielEvaluateurs(users);
            setIsLoading(false);
          }
        } catch (error) {
          if (isMounted) {
            setIsLoading(false);
          }
        }
      })();
    }
    return () => {
      isMounted = false;
    };
  }, [db, open]);

  const handleToggle = (value) => async () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    let groupeRef = await db.collection("Groupes").doc(groupeData.id);
    await groupeRef.update({
      evaluateurs: newChecked,
    });
    setChecked(newChecked);
    groupeData.data.evaluateurs = newChecked;
    setGroupes((prv) => {
      let groupeDataIndex = prv.findIndex(
        (_groupeData) => _groupeData.id === groupeData.id
      );
      if (groupeDataIndex === -1) {
        return prv;
      }
      prv.splice(groupeDataIndex, 1, groupeData);
      return [...prv];
    });
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Tooltip
        arrow
        TransitionComponent={Zoom}
        title="Gérer qui peut voir cette session"
      >
        <IconButton
          className={classes.manageBtn + " manage_evaluators_btn"}
          onClick={handleClickOpen}
          size="medium"
        >
          <SupervisedUserCircleIcon />
        </IconButton>
      </Tooltip>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.dialog}
      >
        <DialogTitle id="alert-dialog-title">
          Qui peut voir cette session ?
        </DialogTitle>
        <DialogContent className={isLoading ? classes.flexedLoading : ""}>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <List className={classes.rootList}>
              {potentielEvaluateurs
                .sort((a, b) => {
                  try {
                    return a.name.toLowerCase() < b.name.toLowerCase()
                      ? -1
                      : a.name.toLowerCase() > b.name.toLowerCase()
                      ? 1
                      : 0;
                  } catch (error) {
                    return 0;
                  }
                })
                .map((potentielEvaluateur) => {
                  if (
                    // potentielEvaluateur.role <= roles.EVALUATEUR &&
                    potentielEvaluateur.role !== roles.SUPER_ADMIN
                  ) {
                    return (
                      <ListItem key={potentielEvaluateur.uid}>
                        <ListItemIcon>
                          <SupervisedUserCircleIcon />
                        </ListItemIcon>
                        <ListItemText
                          id={potentielEvaluateur.uid}
                          primary={potentielEvaluateur.name}
                        />
                        <ListItemSecondaryAction>
                          <Switch
                            edge="end"
                            onChange={handleToggle(potentielEvaluateur.uid)}
                            checked={
                              checked.indexOf(potentielEvaluateur.uid) !== -1
                            }
                            inputProps={{
                              "aria-labelledby": potentielEvaluateur.uid,
                            }}
                          />
                        </ListItemSecondaryAction>
                      </ListItem>
                    );
                  } else {
                    return "";
                  }
                })}
            </List>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
