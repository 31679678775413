import React, { useState, useEffect } from "react";
import firebase from "../lib/firebase";
import { makeStyles } from "@material-ui/core/styles";
import AddSoftskillToDbDialog from "./addsoftskilltodbdialog";
import DeleteSoftskillDialog from "./deletesoftskilldialog";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 400,
    backgroundColor: theme.palette.background.paper,
    padding: 0,
  },
  buttons: {
    marginTop: 8,
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 0 8px 0",
  },
}));

export default function ChoseSoftSkills({
  game,
  setChosenSoftSkills,
  handleClose,
}) {
  const classes = useStyles();
  const db = firebase.firestore();
  const [checked, setChecked] = useState(game.softSkills);
  const [isLoading, setIsLoading] = useState(true);
  const [availableSoftSkillList, setAvailableSoftSkillList] = useState([]);
  useEffect(() => {
    let isMounted = true;
    (async () => {
      try {
        let softSkills = await db
          .collection("Softskills")
          .doc("softskill-list")
          .get();
        if (isMounted) {
          softSkills =
            softSkills.data() && softSkills.data().list
              ? softSkills.data().list
              : [];
          if (Array.isArray(softSkills)) {
            setAvailableSoftSkillList(softSkills);
          }
          setIsLoading(false);
        }
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    })();

    return () => {
      isMounted = false;
    };
  }, [db]);
  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
    setChosenSoftSkills(newChecked);
  };

  return (
    <>
      {isLoading ? (
        <CircularProgress disableShrink />
      ) : (
        <List className={classes.root}>
          {availableSoftSkillList
            .sort((a, b) => {
              try {
                return a.toLowerCase() < b.toLowerCase()
                  ? -1
                  : a.toLowerCase() > b.toLowerCase()
                  ? 1
                  : 0;
              } catch (error) {
                return 0;
              }
            })
            .map((softSkillName) => {
              const labelId = `checkbox-list-label-${softSkillName}`;

              return (
                <ListItem key={softSkillName} role={undefined} dense>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={checked.indexOf(softSkillName) !== -1}
                      tabIndex={-1}
                      onClick={handleToggle(softSkillName)}
                      // disableRipple
                      inputProps={{ "aria-labelledby": labelId }}
                    />
                  </ListItemIcon>
                  <ListItemText id={labelId} primary={softSkillName} />
                  <DeleteSoftskillDialog
                    softSkillName={softSkillName}
                    availableSoftSkillList={availableSoftSkillList}
                    setAvailableSoftSkillList={setAvailableSoftSkillList}
                  />
                </ListItem>
              );
            })}
          <ListItem className={classes.buttons}>
            <AddSoftskillToDbDialog
              availableSoftSkillList={availableSoftSkillList}
              setAvailableSoftSkillList={setAvailableSoftSkillList}
            />
            <Button
              onClick={handleClose}
              className={classes.button}
              color="primary"
            >
              OK
            </Button>
          </ListItem>
        </List>
      )}
    </>
  );
}
