import React, { useContext } from "react";
import { SetGroupesContext } from "../lib/context";
import firebase from "../lib/firebase";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";


const useStyles = makeStyles((theme) => ({
  participantText: {
    fontSize: 18,
    fontWeight: "bold",
    color: "black",
  },
  deleteBtn: {
    color: "red",
  },
}));

export default function DeleteGroupDialog({ participant, groupeData }) {
  const classes = useStyles("");
  const [open, setOpen] = React.useState(false);
  const db = firebase.firestore();
  const setGroupes = useContext(SetGroupesContext);

  const deleteParticipant = () => {
    db.collection("Groupes")
      .doc(groupeData.id)
      .update({
        participants: firebase.firestore.FieldValue.arrayRemove(participant),
      })
      .then(function () {
        console.log("Document successfully deleted!");
        handleClose();
        let newParticipants = groupeData.data.participants.filter(
          (p) => p !== participant
        );
        groupeData.data.participants = newParticipants;
        setGroupes((prv) => {
          let groupeDataIndex = prv.findIndex(
            (_groupeData) => _groupeData.id === groupeData.id
          );
          if (groupeDataIndex === -1) {
            return prv;
          }
          prv.splice(groupeDataIndex, 1, groupeData);
          return [...prv];
        });
      })
      .catch(function (error) {
        console.error("Error removing document: ", error);
        handleClose();
      });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Tooltip placement="right" arrow TransitionComponent={Zoom} title="Supprimer ce participant">
        <IconButton onClick={handleClickOpen} size="medium">
          <DeleteForeverIcon />
        </IconButton>
      </Tooltip>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Suppression d'un participant
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Voulez vous supprimer
            <span className={classes.participantText}>{" " + participant}</span>
            {" de la session ?"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            ANNULER
          </Button>
          <Button onClick={deleteParticipant} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
