import React from "react";
import firebase from "../lib/firebase";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { roles, rolesLabel } from "../lib/roles";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 100,
  },
}));

export default function ControlledOpenSelect({
  selectedRole,
  setUsers,
  users,
  user,
}) {
  const classes = useStyles();
  const db = firebase.firestore();
  const [roleChosen, setRoleChosen] = React.useState(selectedRole);
  const [open, setOpen] = React.useState(false);

  const handleChange = async (event) => {
    let newRole = event.target.value;
    if (newRole !== user.role) {
      if (user.role > newRole) {
        // nouveau role meilleur que le precedent, envoie d'un mail pour prevenir l'user
        fetch("https://recrutgame.synergiefamily.com/api/alertaccepted", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userMail: user.email,
            newRole: rolesLabel[newRole],
          }),
        });
      }

      user.role = newRole;
      setUsers([...users.filter((u) => u.uid !== user.uid), user]);
      await db.collection("Utilisateurs").doc(user.uid).update({
        role: newRole,
      });
    }
    setRoleChosen(newRole);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <div>
      <FormControl className={classes.formControl}>
        <InputLabel id="demo-controlled-open-select-label">Role</InputLabel>
        <Select
          labelId="demo-controlled-open-select-label"
          id="demo-controlled-open-select"
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          value={roleChosen}
          onChange={handleChange}
          className={classes.selectRoot}
        >
          <MenuItem value={roles.ADMIN}>{rolesLabel[roles.ADMIN]}</MenuItem>
          <MenuItem value={roles.EVALUATEUR}>
            {rolesLabel[roles.EVALUATEUR]}
          </MenuItem>
          <MenuItem value={roles.GUEST}>{rolesLabel[roles.GUEST]}</MenuItem>
          <MenuItem value={roles.BANNED}>{rolesLabel[roles.BANNED]}</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}
