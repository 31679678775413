import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import ChoseSoftSkills from "./chosesoftskills";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  dialog: {
    maxHeight: "calc(100vh - 180px)",
  },
}));

export default function ChoseSoftSkillsDialog({
  game,
  games,
  setGames,
  savePremadeGames,
  fromManageGames,
}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [chosenSoftSkills, setChosenSoftSkills] = useState(game.softSkills);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    games.forEach((_game) => {
      if (_game.name === game.name) {
        game.softSkills = chosenSoftSkills;
      }
    });
    if (fromManageGames) {
      savePremadeGames(games);
    }
    setGames([...games]);
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <Button  variant="outlined" onClick={handleClickOpen} size="small" color="primary">
        Choisir des soft skills
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent className={classes.dialog}>
          <ChoseSoftSkills
            handleClose={handleClose}
            game={game}
            setChosenSoftSkills={setChosenSoftSkills}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}
