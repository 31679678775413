import React, { useEffect, useState } from "react";
import firebase from "../lib/firebase";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Alert from "@material-ui/lab/Alert";
import CircularProgress from "@material-ui/core/CircularProgress";
import { roles, rolesLabel, rolesColor } from "../lib/roles";
import Chip from "@material-ui/core/Chip";
import SelectRole from "./selectrole";
import DeleteUserDialog from "./deleteuserdialog";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    width: "100%",
    maxWidth: 500,
    maxHeight: "96%",
    overflow: "auto",
    backgroundColor: theme.palette.background.paper,
    boxShadow:
      "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
    borderRadius: "5px",
    padding: 10,
  },
  userName: {
    textAlign: "center",
    fontSize: 16,
    color: "black",
  },
}));

export default function SuperAdminPage() {
  const classes = useStyles();
  const db = firebase.firestore();
  const [isLoading, setIsLoading] = useState(true);
  const [users, setUsers] = useState([]);
  useEffect(() => {
    let isMounted = true;

    (async () => {
      try {
        const usersSnapshot = await db
          .collection("Utilisateurs")
          .orderBy("name", "asc")
          .get();
        if (isMounted) {
          let users = [];
          usersSnapshot.forEach(function (doc) {
            const user = doc.data();
            users.push({ ...user, uid: doc.id });
          });
          setUsers(users);
          setIsLoading(false);
        }
      } catch (error) {
        if (isMounted) {
          setIsLoading(false);
        }
      }
    })();

    return () => {
      isMounted = false;
    };
  }, [db]);
  return (
    <>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <div className={classes.root}>
          {users && users.length === 1 ? (
            <Alert severity="error">Aucun utilisateur</Alert>
          ) : (
            <>
              {users
                .sort((a, b) => a.role - b.role || (a.name > b.name ? 1 : -1))
                .map((_user) => {
                  if (_user.role !== roles.SUPER_ADMIN) {
                    return (
                      <List
                        key={_user.uid}
                        dense
                        component="nav"
                        aria-label="main mailbox folders"
                      >
                        <ListItem>
                          <Chip
                            style={{
                              backgroundColor: rolesColor[_user.role],
                              color: "white",
                              width: 85,
                            }}
                            label={rolesLabel[_user.role]}
                          />
                          <ListItemText
                            classes={{ primary: classes.userName }}
                            primary={_user.name}
                          />
                          <SelectRole
                            selectedRole={_user.role}
                            users={users}
                            user={_user}
                            setUsers={setUsers}
                          />
                          <DeleteUserDialog
                            users={users}
                            userToDelete={_user}
                            setUsers={setUsers}
                          />
                        </ListItem>
                      </List>
                    );
                  }
                  return "";
                })}
            </>
          )}
        </div>
      )}
    </>
  );
}
