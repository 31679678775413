import React, { useEffect, useRef } from "react";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import PhotoLibraryIcon from "@material-ui/icons/PhotoLibrary";
import IconButton from "@material-ui/core/IconButton";
import NameAndBarVisual from "./nameandbarvisual";
import "../styles/evalcard.css";

export default function ClassementCard({
  results,
  groupeTitre,
  evaluateurName,
}) {
  const evalCardContainerElem = useRef(null);
  const animTimeout = useRef(null);
  let clonedResults = { ...results };
  delete clonedResults.name;
  clonedResults = Object.values(clonedResults);
  useEffect(() => {
    let isMounted = true;
    if (evalCardContainerElem && evalCardContainerElem.current) {
      animTimeout.current = setTimeout(() => {
        if (isMounted) {
          evalCardContainerElem.current.classList.add("anim_in");
        }
      }, 10);
    }
    return () => {
      isMounted = false;
      clearTimeout(animTimeout.current);
    };
  }, [evalCardContainerElem]);
  const getPercFromEvalNumberAndIterations = (evalNn, iterations) => {
    return Math.round(((evalNn ) / iterations) * 25);
  };
  const getPercentageFromResults = (_results) => {
    let totalPercentage = 0;
    let totalIterations = 0;
    let totalEvalNumber = 0;
    Object.values(_results).forEach((skillResult) => {
      if (typeof skillResult === "object") {
        totalIterations += skillResult.iterations;
        totalEvalNumber += skillResult.evalNumber;
      }
    });
    if (totalEvalNumber === 0) {
      totalPercentage = 0;
    } else {
      totalPercentage = Math.round(
        ((totalEvalNumber ) / totalIterations) * 25
        // 0 -> non évalué
        // 1 -> 25%
        // 2 -> 50%
        // 3 -> 75%
        // 4 -> 100%
      );
    }
    return totalPercentage;
  };
  // const exportCardBilan = (outputType) => {
  //   let bilanTabs = document.querySelector(".bilan_tabs");
  //   let chartToolBars = document.querySelectorAll(".apexcharts-toolbar");
  //   let evalCardContainers = document.querySelectorAll(".eval_card_container");
  //   let commentsLabel = document.querySelectorAll(".comments_label");
  //   let unwantedLines = document.querySelectorAll(".apexcharts-ycrosshairs");
  //   let progressBars = document.querySelectorAll(".MuiLinearProgress-bar");
  //   let groupeTitreElem = document.querySelector(".groupe_titre");
  //   if (groupeTitreElem) {

  //       groupeTitreElem.style.display = "block";

  //   }
  //   if (commentsLabel) {
  //     commentsLabel.forEach((label) => {
  //       label.style.display = "none";
  //     });
  //   }
  //   if (unwantedLines) {
  //     unwantedLines.forEach((line) => {
  //       line.style.display = "none";
  //     });
  //   }
  //   if (bilanTabs) {
  //     bilanTabs.style.display = "none";
  //   }
  //   if (chartToolBars) {
  //     chartToolBars.forEach((chartToolBar) => {
  //       chartToolBar.style.display = "none";
  //     });
  //   }
  //   if (progressBars) {
  //     progressBars.forEach((progressBar) => {
  //       progressBar.style.transition = "none";
  //       progressBar.transformStyleBeforeExport = progressBar.style.transform;
  //       let transformStringNumber =
  //         progressBar.transformStyleBeforeExport.replace(/^\D+/g, "");

  //       progressBar.style.width =
  //         100 -
  //         parseInt(
  //           transformStringNumber.length > 0 ? transformStringNumber : 0
  //         ) +
  //         "%";
  //       progressBar.style.transform = "";
  //     });
  //   }
  //   if (evalCardContainers) {
  //     evalCardContainers.forEach((evalCardContainer) => {
  //       evalCardContainer.style.border = "1px solid rgba(0,0,0,0.2)";
  //     });
  //   }
  //   if (evalCardContainerElem.current) {
  //     if (outputType === "pdf") {
  //       var worker = window
  //         .html2pdf()
  //         // .set({
  //         //   jsPDF: {
  //         //     unit: "px",
  //         //     format: [
  //         //       evalCardContainerElem.current.offsetHeight*1.2,
  //         //       evalCardContainerElem.current.offsetWidth*1.2,

  //         //     ],
  //         //     hotfixes: ["px_scaling"],
  //         //   },
  //         // })
  //         .from(evalCardContainerElem.current)
  //         .save(
  //           groupeTitre +
  //             "_classement_" +
  //             (evaluateurName ? "evalué_par_" + evaluateurName : "total")
  //         );
  //     } else {
  //       var worker = window
  //         .html2pdf()
  //         .set({
  //           jsPDF: { unit: "mm", format: [200, 200], orientation: "portrait" },
  //         })
  //         .from(evalCardContainerElem.current)
  //         .outputImg("datauri")
  //         .then((test) => {
  //           let link = document.createElement("a");
  //           link.href = test;
  //           link.target = "_blank";
  //           link.download =
  //             groupeTitre +
  //             "_classement_" +
  //             (evaluateurName ? "evalué_par_" + evaluateurName : "total") +
  //             ".jpeg";
  //           document.body.appendChild(link);
  //           link.click();
  //           document.body.removeChild(link);
  //         });
  //     }
  //   }
  //   if (groupeTitreElem) {
  //     setTimeout(() => {
  //       groupeTitreElem.style.display = "none";
  //     }, 100);
  //   }
  //   if (commentsLabel) {
  //     setTimeout(() => {
  //       commentsLabel.forEach((label) => {
  //         label.style.display = "";
  //       });
  //     }, 100);
  //   }
  //   if (bilanTabs) {
  //     setTimeout(() => {
  //       bilanTabs.style.display = "";
  //     }, 100);
  //   }
  //   if (chartToolBars) {
  //     setTimeout(() => {
  //       chartToolBars.forEach((chartToolBar) => {
  //         chartToolBar.style.display = "flex";
  //       });
  //     }, 100);
  //   }
  //   if (progressBars) {
  //     setTimeout(() => {
  //       progressBars.forEach((progressBar) => {
  //         progressBar.style.width = "100%";
  //         progressBar.style.transition = "transform 0.3s";
  //         progressBar.style.transform = progressBar.transformStyleBeforeExport;
  //       });
  //     }, 100);
  //   }
  //   if (evalCardContainers) {
  //     setTimeout(() => {
  //       evalCardContainers.forEach((evalCardContainer) => {
  //         evalCardContainer.style.border = "none";
  //       });
  //     }, 100);
  //   }
  // };
  return (
    <div
      ref={evalCardContainerElem}
      className="eval_card_container classement_card"
    >
      <div className="skill_card_name ">Classement global</div>
      {/* <IconButton
        className="to_pdf_btn"
        onClick={() => {
          exportCardBilan("pdf");
        }}
      >
        <PictureAsPdfIcon style={{ color: "#0e7681" }} />
      </IconButton>
      <IconButton
        className="to_jpeg_btn"
        onClick={() => {
          exportCardBilan("jpeg");
        }}
      >
        <PhotoLibraryIcon style={{ color: "#0e7681" }} />
      </IconButton> */}
      <div className="skill_card_bars_container">
        {clonedResults
          .sort(
            (a, b) => getPercentageFromResults(a) < getPercentageFromResults(b)
          )
          .map((result, index) => (
            <NameAndBarVisual
              key={result.name + Math.random()}
              isLast={index === clonedResults.length - 1}
              name={result.name}
              percentage={getPercentageFromResults(result)}
              isClassement={true}
              index={index}
            />
          ))}
      </div>
    </div>
  );
}
