import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import EditIcon from "@material-ui/icons/Edit";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  editNameIcon: {
    marginLeft: "4px",
    padding: "13px",
    cursor: "pointer",
    width: "48px",
    height: "48px",
    borderRadius: "50%",
    transition: "background-color 0.2s ease-in-out",
    "&:hover": {
      backgroundColor: "#ccc",
      color: "#6B6B6B",
    },
  },
}));
export default function FormDialog({
  setGames,
  games,
  game,
  savePremadeGames,
  fromManageGames,
}) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [newName, setNewName] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const handleClose = () => {
    setOpen(false);
  };
  const handleConfirm = async () => {
    try {
      let error = null;
      if (newName.length === 0) {
        error = "Veuillez saisir un nom de jeu";
      } else if (
        games.findIndex(
          (_game) => _game.name.toLowerCase() === newName.toLowerCase()
        ) !== -1
      ) {
        error = "Ce nom de jeu existe déjà";
      }
      if (!error) {
        games.forEach((_game) => {
          if (_game.name === game.name) {
            game.name = newName;
          }
        });
        if (fromManageGames) {
          savePremadeGames(games);
        }
        setGames([...games]);
        handleClose();
      } else {
        setErrorMsg(error);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleInputChange = (e) => {
    setNewName(e.target.value);
  };

  return (
    <div className={classes.root}>
      <Tooltip
        placement="top"
        arrow
        TransitionComponent={Zoom}
        title="Modifier le nom du jeu"
      >
        <EditIcon
          className={classes.editNameIcon}
          onClick={() => {
            setOpen(true);
          }}
        />
      </Tooltip>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent>
          <DialogContentText>Veuillez choisir le nom du jeu.</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Nom du jeu"
            type="text"
            fullWidth
            onChange={handleInputChange}
            autoComplete="off"
          />
          {errorMsg.length > 0 ? (
            <Alert severity="error">{errorMsg}</Alert>
          ) : (
            ""
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Annuler
          </Button>
          <Button onClick={handleConfirm} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
