import React, { useContext } from "react";
import { SetGroupesContext } from "../lib/context";
import firebase from "../lib/firebase";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import UnarchiveIcon from "@material-ui/icons/Unarchive";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";

const useStyles = makeStyles((theme) => ({
  titreGroupe: {
    fontSize: 18,
    fontWeight: "bold",
    color: "black",
  },
  deleteBtn: {
    color: "#707070",
    margin: 0,
    padding: 8,
  },
  firstButton: { marginRight: "auto" },
}));

export default function UnarchiveGroupDialog({ groupeData }) {
  const classes = useStyles("");
  const [open, setOpen] = React.useState(false);
  const db = firebase.firestore();
  const setGroupes = useContext(SetGroupesContext);

  const restaurer = () => {
    db.collection("Groupes")
      .doc(groupeData.id)
      .update({ archived: false })
      .then(function () {
        console.log("Document successfully unarchived!");
        setGroupes((prv) =>
          prv.map((grp) => {
            if (grp.id === groupeData.id) {
              grp.data.archived = false;
              return { ...grp };
            }
            return grp;
          })
        );
        // handleClose();
      })
      .catch(function (error) {
        console.error("Error archiving document: ", error);
        handleClose();
      });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Tooltip arrow TransitionComponent={Zoom} title="Restaurer la session">
        <IconButton
          className={classes.deleteBtn + " delete_grp_btn"}
          onClick={handleClickOpen}
          size="medium"
          color="primary"
        >
          <UnarchiveIcon />
        </IconButton>
      </Tooltip>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Restaurer la session</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {"Voulez vous restaurer la session: "}
            <span className={classes.titreGroupe}>
              {groupeData.data.titre}
            </span>{" "}
            ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.firstButton}
            onClick={handleClose}
            color="primary"
          >
            ANNULER
          </Button>

          <Button
            onClick={restaurer}
            color="primary"
            variant="contained"
            autoFocus
          >
            RESTAURER
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
