import React, { useEffect, useState, useCallback, useRef } from "react";
import firebase from "../lib/firebase";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PersonIcon from "@material-ui/icons/Person";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from "@material-ui/lab/Alert";
import EvalCard from "./evalcard";
import SkillCard from "./skillcard";
import ClassementCard from "./classementcard";
import ChoseTypeDeBilanButtons from "./chosetypedebilanbuttons";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

const GreenSwitch = withStyles({
  switchBase: {
    "&$checked": {
      color: "#0E7681",
    },
    "&$checked + $track": {
      backgroundColor: "#0E7681",
    },
  },
  checked: {},
  track: {},
})(Switch);
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    paddingTop: 20,
    position: "relative",
  },
  groupeTitle: {
    width: "100%",
    textAlign: "center",
    fontSize: 20,
    marginBottom: 15,
    display: "none",
    // color: "rgba(14, 118, 129, 0.856)",
  },
  loadingContainer: {
    width: "100vw",
    maxHeight: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  errorContainer: {
    width: "100vw",
    maxHeight: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  tabs: {
    justifyContent: "center",
    height: 70,
    maxHeight: 70,
    fontSize: 14,
    maxWidth: "100vw",
    background: "transparent",
    "& .MuiTabs-scroller": {
      background: "transparent",
      paddingLeft: 20,
      paddingRight: 20,
      flexGrow: 0,
      // maxWidth: "80vw"
    },
    "& .MuiPaper-root": {
      background: "transparent",
    },
    "& .MuiButtonBase-root": {
      minWidth: 100,
      padding: 0,
    },
    "& .MuiTabs-flexContainer": {
      background: "transparent",
      height: 70,
      maxHeight: 70,
      maxWidth: "100vw",
    },
    "& .Mui-selected": {
      color: "#0E7681",
    },
    "& .MuiTabs-indicator": {
      background: "#0E7681",
    },
    "& .MuiTab-wrapper": {
      [theme.breakpoints.down("xs")]: {
        fontSize: 12,
      },
      padding: 10,
    },
  },
  evalCards: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    flexWrap: "wrap",
    marginTop: 20,
    marginBottom: 30,
  },
  viewFaiblessesSwitch: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginTop: 20,
    marginBottom: 0,
  },
}));

export default function Bilan({
  groupeId,
  refreshRdm,
  chartType,
  groupeTitre,
  groupeDate,
  typeDeBilan,
  setTypeDeBilan,
}) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState([]);
  const [evalsData, setEvalsData] = useState([]);
  const [totalResults, setTotalResults] = useState([]);
  const [errorMsg, setErrorMsg] = useState("Une erreur est surevenue");
  const [value, setValue] = useState(0);
  const [viewFaiblesses, setViewFaiblaisses] = useState(false);
  const db = firebase.firestore();

  const getTotal = useCallback((_evalsData, evaluatorName) => {
    let results = [];
    if (evaluatorName) {
      _evalsData = _evalsData.filter(
        (data) => data.evaluateurName === evaluatorName
      );
    }
    _evalsData.forEach((ev) => {
      ev.participants.forEach((participant) => {
        if (hasOneSkillEvaluated(participant)) {
          let participantResult = results.find(
            (r) => r.name === participant.name
          );
          if (!participantResult) {
            participantResult = { name: participant.name };
          }
          Object.values(participant).forEach((jeu) => {
            if (typeof jeu === "object") {
              Object.entries(jeu).forEach(([skill, evalNumber]) => {
                if (evalNumber) {
                  if (!participantResult[skill]) {
                    participantResult[skill] = { iterations: 1, evalNumber };
                  } else {
                    participantResult[skill].iterations++;
                    participantResult[skill].evalNumber += evalNumber;
                  }
                }
              });
            }
          });
          let thisResultIndex = results.findIndex(
            (r) => r.name === participant.name
          );
          if (thisResultIndex >= 0) {
            results.splice(thisResultIndex, 1, participantResult);
          } else {
            results.push(participantResult);
          }
        }
      });
    });
    return results;
  }, []);

  const getResultsFromParticipantEval = (pEval) => {
    let res = {};
    Object.values(pEval).forEach((jeu) => {
      if (typeof jeu === "object") {
        Object.entries(jeu).forEach(([skill, evalNumber]) => {
          if (evalNumber) {
            if (!res[skill]) {
              res[skill] = { iterations: 1, evalNumber };
            } else {
              res[skill].iterations++;
              res[skill].evalNumber += evalNumber;
            }
          }
        });
      }
    });
    return res;
  };
  const getPercentageFromResults = (_results) => {
    let totalPercentage = 0;
    let totalIterations = 0;
    let totalEvalNumber = 0;
    Object.values(_results).forEach((skillResult) => {
      if (typeof skillResult === "object") {
        totalIterations += skillResult.iterations;
        totalEvalNumber += skillResult.evalNumber;
      }
    });
    if (totalEvalNumber === 0) {
      totalPercentage = 0;
    } else {
      totalPercentage = Math.round(
        (totalEvalNumber / totalIterations) * 25
        // 0 -> non évalué
        // 1 -> 25%
        // 2 -> 50%
        // 3 -> 75%
        // 4 -> 100%
      );
    }
    return totalPercentage;
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const hasOneSkillEvaluated = (participantEval) => {
    let hasOne = false;
    Object.values(participantEval).forEach((gameEval) => {
      if (typeof gameEval === "object" && gameEval) {
        Object.values(gameEval).forEach((skillScore) => {
          if (skillScore) {
            hasOne = true;
          }
        });
      }
    });
    return hasOne;
  };
  const getResultsBySkills = (_evalsData, evalName) => {
    let totalRes = getTotal(_evalsData, evalName);
    totalRes = totalRes.sort(
      (a, b) => getPercentageFromResults(b) - getPercentageFromResults(a)
    );
    let totalResBySkills = {};

    try {
      totalRes.forEach((res) => {
        Object.keys(res).forEach((resKey) => {
          if (resKey !== "name") {
            if (!totalResBySkills[resKey]) {
              totalResBySkills[resKey] = {};
            }
            if (!totalResBySkills[resKey][res.name]) {
              totalResBySkills[resKey][res.name] = {
                iterations: res[resKey].iterations,
                evalNumber: res[resKey].evalNumber,
              };
            }
          }
        });
      });
      return totalResBySkills;
    } catch (error) {
      console.log(error);
      return {};
    }
  };
  useEffect(() => {
    let isMounted = true;
    const fetchEvalData = async () => {
      setIsLoading(true);
      try {
        let fetchedGroupeData = await db
          .collection("Groupes")
          .doc(groupeId)
          .get();
        fetchedGroupeData = {
          id: fetchedGroupeData.id,
          ...fetchedGroupeData.data(),
        };
        if (fetchedGroupeData && isMounted) {
          let fetchedEvalSnapshot = await db
            .collection("Evaluations")
            .where("groupe", "==", groupeId)
            .get();
          if (isMounted) {
            let evalsData = [];
            fetchedEvalSnapshot.forEach(function (doc) {
              evalsData.push({ ...doc.data(), id: doc.id });
            });
            evalsData = evalsData.filter((ev) => {
              let isValidEval = false;
              try {
                ev.participants.forEach((p) => {
                  if (typeof p === "object") {
                    Object.values(p).forEach((jeu) => {
                      if (typeof jeu === "object") {
                        Object.values(jeu).forEach((evalNumber) => {
                          if (
                            typeof evalNumber === "number" &&
                            evalNumber > 0
                          ) {
                            isValidEval = true;
                          }
                        });
                      }
                    });
                  }
                });
              } catch (error) {
                console.log(error);
              }

              return isValidEval;
            });
            // on enleve les evaluations d'un participant supprimé
            evalsData.forEach((ev) => {
              ev.participants = ev.participants.filter((p) =>
                fetchedGroupeData.participants.includes(p.name)
              );
            });
            if (evalsData.length > 0) {
              if (fetchedGroupeData.participants.length === 0) {
                setErrorMsg("Ce groupe ne contient aucun participant");
                setIsLoading(false);
              } else {
                if (isMounted) {
                  let totalRes = getTotal(evalsData);
                  totalRes = totalRes.sort(
                    (a, b) =>
                      getPercentageFromResults(b) - getPercentageFromResults(a)
                  );
                  setTotalResults(totalRes);
                  setEvalsData(evalsData);
                  setIsLoading(false);
                }
              }
            } else {
              setErrorMsg(
                "Aucune évaluation effectuée sur ce groupe pour le moment"
              );
              setIsLoading(false);
            }
          }
        }
        if (isMounted) {
          setIsLoading(false);
        }
      } catch (e) {
        if (isMounted) {
          console.log(e);
          setErrorMsg("Une erreur est survenue");
          setIsLoading(false);
        }
      }
    };
    fetchEvalData();
    return () => {
      isMounted = false;
    };
  }, [refreshRdm, db, groupeId, getTotal]);

  const handleSwitchViewFaiblesses = (e) => {
    setViewFaiblaisses(e.target.checked);
  };

  return (
    <div className={classes.root + " bilan_view"}>
      {!isLoading && evalsData && evalsData.length > 0 ? (
        <>
          <div className={classes.groupeTitle + " groupe_titre"}>
            {groupeTitre}
            <br></br>
            {new Date(groupeDate.seconds * 1000).toLocaleDateString()}
            {value === 0 ? null : (
              <>
                <br></br>
                {"Évalué par: " + evalsData[value - 1].evaluateurName}
              </>
            )}
          </div>
          <Tabs
            className={classes.tabs + " bilan_tabs"}
            value={value}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChange}
            aria-label="tabs"
            variant="scrollable"
            scrollButtons="auto"
          >
            {evalsData.length > 1 ? (
              <Tab
                key="total"
                label="Total"
                icon={<AssignmentTurnedInIcon />}
              />
            ) : (
              ""
            )}

            {evalsData.map((evalData) => (
              <Tab
                key={evalData.evaluateurUid}
                label={
                  evalData.evaluateurName
                    ? evalData.evaluateurName
                    : evalData.evaluateurMail.split("@")[0]
                }
                icon={<PersonIcon />}
              />
            ))}
          </Tabs>

          <ChoseTypeDeBilanButtons
            typeDeBilan={typeDeBilan}
            setTypeDeBilan={setTypeDeBilan}
          />
          {typeDeBilan === "cartes_individuelles" ? (
            <>
              <FormControlLabel
                className={
                  classes.viewFaiblessesSwitch + " view_faiblesses_switch"
                }
                control={
                  <GreenSwitch
                    checked={viewFaiblesses}
                    onChange={handleSwitchViewFaiblesses}
                    color="primary"
                  />
                }
                label="Voir axes d'amélioration"
              />
              <div className={classes.evalCards}>
                {evalsData.length > 1 && value === 0 && totalResults
                  ? totalResults.map((result, index) => (
                      <EvalCard
                        evaluateurName={
                          value === 0
                            ? null
                            : evalsData[value - 1].evaluateurName
                        }
                        key={result.name + index.toString()}
                        results={result}
                        percentage={getPercentageFromResults(result)}
                        pName={result.name}
                        index={index}
                        chartType={chartType}
                        groupeId={groupeId}
                        groupeTitre={groupeTitre}
                        viewFaiblesses={viewFaiblesses}
                      />
                    ))
                  : ""}
                {evalsData.map((ev, index) => {
                  if (
                    (evalsData.length > 1 && index + 1 === value) ||
                    (evalsData.length === 1 && index === value)
                  ) {
                    return ev.participants
                      .sort(
                        (a, b) =>
                          getPercentageFromResults(
                            getResultsFromParticipantEval(b)
                          ) -
                          getPercentageFromResults(
                            getResultsFromParticipantEval(a)
                          )
                      )
                      .map((participantEval, i) => {
                        let res =
                          getResultsFromParticipantEval(participantEval);
                        return hasOneSkillEvaluated(participantEval) ? (
                          <EvalCard
                            evaluateurName={
                              value === 0
                                ? null
                                : evalsData[value - 1].evaluateurName
                            }
                            key={participantEval.name + index}
                            results={res}
                            percentage={getPercentageFromResults(res)}
                            index={i}
                            chartType={chartType}
                            pName={participantEval.name}
                            groupeId={groupeId}
                            groupeTitre={groupeTitre}
                            viewFaiblesses={viewFaiblesses}
                          />
                        ) : (
                          ""
                        );
                      });
                  }
                  return "";
                })}
              </div>
            </>
          ) : typeDeBilan === "cartes_par_soft_skills" ? (
            <div className={classes.evalCards}>
              {evalsData.length > 0
                ? Object.entries(
                    getResultsBySkills(
                      evalsData,
                      value === 0 ? null : evalsData[value - 1].evaluateurName
                    )
                  )
                    .sort((a, b) => (a[0] > b[0] ? 1 : -1))
                    .map((keyAndValue, index) => (
                      <SkillCard
                        evaluateurName={
                          value === 0
                            ? null
                            : evalsData[value - 1].evaluateurName
                        }
                        key={keyAndValue[0] + index.toString()}
                        results={keyAndValue[1]}
                        skillName={keyAndValue[0]}
                        index={index}
                        groupeTitre={groupeTitre}
                      />
                    ))
                : ""}
            </div>
          ) : (
            <div className={classes.evalCards}>
              {evalsData.length > 0 && totalResults && (
                <ClassementCard
                  evaluateurName={
                    value === 0 ? null : evalsData[value - 1].evaluateurName
                  }
                  groupeTitre={groupeTitre}
                  results={
                    value === 0
                      ? totalResults
                      : getTotal(evalsData, evalsData[value - 1].evaluateurName)
                  }
                />
              )}
            </div>
          )}
        </>
      ) : isLoading ? (
        <div className={classes.loadingContainer}>
          <CircularProgress />
        </div>
      ) : (
        <div className={classes.errorContainer}>
          <Alert severity="error">{errorMsg}</Alert>
        </div>
      )}
    </div>
  );
}
