import React, { useContext, useState, useEffect, useRef } from "react";
import { UserContext, UserRoleContext } from "../lib/context";
import { roles } from "../lib/roles";
import firebase from "../lib/firebase";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import InsertCommentIcon from "@material-ui/icons/InsertComment";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import CircularProgress from "@material-ui/core/CircularProgress";
import CommentRow from "./commentrow";
import CommentForExport from "./commentforexport";
import DialogTitle from "@material-ui/core/DialogTitle";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  rootFromEvalCard: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "flex-end",
    width: "100%",
  },
  dialogRoot: {
    zIndex: "100000!important",
    "& .MuiDialog-container": {
      minWidth: "60vw",
    },
    "& .MuiPaper-root": {
      minWidth: "60vw",
    },
  },
  dialog: {
    maxHeight: "100%",
    width: "100%",

    position: "relative",
    paddingBottom: 52,
  },
  showCommentsBtn: {
    marginRight: "10px",
    // padding: "13px",
    cursor: "pointer",
    // width: "48px",
    //   height: "48px",
    //   borderRadius: "50%",
    transition: "background-color 0.2s ease-in-out",
    "&:hover": {
      backgroundColor: "#ccc",
    },
  },
  addCommentInput: {
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)",
    bottom: "48px",
    width: "95%",
    background: "white",
  },
  commentsBtnWithLabel: {
    cursor: "pointer",
    alignSelf: "flex-end",
    marginTop: 8,
    color: "#0e7681",
    fontSize: 14,
    width: "100%",
    textAlign: "right",
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));
export default function FormDialog({
  groupeId,
  evaluatedUserName,
  inEvalCard,
}) {
  const classes = useStyles();
  const userRole = useContext(UserRoleContext);
  const user = useContext(UserContext);
  const db = firebase.firestore();
  const [open, setOpen] = useState(false);
  const commentsContainerRef = useRef();
  const [newComment, setNewComment] = useState("");
  const [commentsNb, setCommentsNb] = useState(0);
  const [comments, setComments] = useState([]);
  const [loadingConfirm, setLoadingConfirm] = useState(false);
  const [loadingComments, setLoadingComments] = useState(true);

  useEffect(() => {
    let isMounted = true;
    if (open || inEvalCard) {
      const fetchComments = async () => {
        if (isMounted) {
          let fetchedCommentsSnapshot = await db
            .collection("Comments")
            .where("groupeId", "==", groupeId)
            .where("evaluatedUserName", "==", evaluatedUserName)
            .get();
          let commentsData = [];
          fetchedCommentsSnapshot.forEach(function (doc) {
            commentsData = [
              ...commentsData,
              { ...doc.data(), id: doc.id },
            ].sort((a, b) => (a.dateSent > b.dateSent ? -1 : 1));
          });

          setComments(commentsData);
          setCommentsNb(commentsData.length);
          setLoadingComments(false);
        }
      };
      fetchComments();
    }

    return () => {
      isMounted = false;
    };
  }, [open]);
  useEffect(() => {
    if (commentsContainerRef && commentsContainerRef.current) {
      commentsContainerRef.current.scrollTo({ top: 0, behavior: "smooth" });
      setNewComment("");
    }
  }, [comments]);
  const handleClose = () => {
    setOpen(false);
  };
  const handleSendComment = async () => {
    try {
      if (newComment.length > 0) {
        setLoadingConfirm(true);
        let newCommentData = {
          author: user.displayName,
          authorUid: user.uid,
          message: newComment,
          dateSent: Date.now(),
          groupeId,
          evaluatedUserName,
        };
        let docRef = await db.collection("Comments").add(newCommentData);

        setLoadingConfirm(false);
        setComments((prev) =>
          [...prev, { ...newCommentData, id: docRef.id }].sort((a, b) =>
            a.dateSent > b.dateSent ? -1 : 1
          )
        );
      }
    } catch (error) {
      console.log(error);
      setLoadingConfirm(false);
    }
  };
  const handleInputChange = (e) => {
    setNewComment(e.target.value);
  };

  return (
    <div className={inEvalCard ? classes.rootFromEvalCard : classes.root}>
      {commentsNb > 0 && (
        <div className="comments_for_export">
          <div className="comments_for_export_label">
            {commentsNb > 1 ? "Commentaires:" : "Commentaire:"}
          </div>
          {comments.map((comment) => (
            <CommentForExport key={comment.id + "2"} comment={comment} />
          ))}
        </div>
      )}

      {
        <Tooltip
          placement="top"
          arrow
          TransitionComponent={Zoom}
          title="Voir / Ajouter commentaires"
        >
          {inEvalCard ? (
            <div
              onClick={() => {
                setOpen(true);
              }}
              className={classes.commentsBtnWithLabel + " comments_label"}
            >
              {commentsNb +
                " " +
                (commentsNb > 1 ? "Commentaires" : "Commentaire")}
            </div>
          ) : (
            <InsertCommentIcon
              style={{ color: "#0e7681" }}
              className={classes.showCommentsBtn}
              onClick={() => {
                setOpen(true);
              }}
            />
          )}
        </Tooltip>
      }
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        className={classes.dialogRoot}
      >
        <DialogTitle id="alert-dialog-title">
          Commentaires à propos de {evaluatedUserName}
        </DialogTitle>
        <DialogContent ref={commentsContainerRef} className={classes.dialog}>
          {loadingComments ? (
            <CircularProgress />
          ) : (
            <>
              {comments.map((comment) => (
                <CommentRow
                  key={comment.id}
                  comment={comment}
                  setComments={setComments}
                  setCommentsNb={setCommentsNb}
                />
              ))}
            </>
          )}
        </DialogContent>
        {userRole <= roles.EVALUATEUR && (
          <>
            <TextField
              className={classes.addCommentInput}
              value={newComment}
              autoFocus
              margin="dense"
              id="name"
              label="Écrivez un commentaire"
              type="text"
              fullWidth
              onChange={handleInputChange}
              autoComplete="off"
              autoFocus={false}
            />
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Fermer
              </Button>
              <Button
                disabled={false}
                onClick={handleSendComment}
                color="primary"
              >
                Envoyer
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </div>
  );
}
